/* =============================================================================
 * interactive
 *
 * postcss-bem-linter: define interactive
============================================================================= */
.c-interactive {
	background-color:var(--block-bg);
	color:var(--text);
	text-align:center;
}
.c-interactive__text {
	font-size:var(--type-sz-20);
	line-height:var(--type-lh-20);
}
.c-interactive iframe {
	max-width:100%;
}
.c-interactive__embed {
	position:relative;
	max-width:100%;
	height:100%;
	height:auto;
	overflow:hidden;
	padding-bottom:56.25%;
}
.c-interactive__embed iframe,
.c-interactive__embed object,
.c-interactive__embed embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
