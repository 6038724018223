/* =============================================================================
 * Header
 * postcss-bem-linter: define header
============================================================================= */

@custom-media --header-break (min-width: 902px);
.c-header {
	position:-webkit-sticky;
	position:fixed;
	top:0;
	z-index:100;
	width:100%;
	height:70px;
	background-color:var(--white);
	font-weight:500;
	transition:transform 0.3s;
	transform:translateX(0);

	@media (--md) {
		height:auto;
	}
}

.c-header__breadcrumbs {
	background-color:var(--white);
	height: 49px;
	border-top: 2px solid #f1eeeb;
}

.c-header__inner {
	display:flex;
	position:relative;
	justify-content:space-between;
	align-items:center;
	padding-top:20px;
	padding-bottom:20px;

	@media (--header-break) {
		align-items:flex-end;
	}

	@media (--md) {
		padding-top:30px;
		padding-bottom:30px;
	}
}
.c-header__utilities {
	display:flex;
	position:relative;
	justify-content:flex-end;
	flex-grow:1;
	margin-left:auto;

	@media (--header-break) {
		flex-grow:unset;
		margin-bottom:6px;
		padding:0;
	}
}
.c-header__nav {
	order:1;
	height:20px;

	@media (--header-break) {
		order:0;
		flex-grow:1;
		height:auto;
	}
}
.c-header__nav-list {
	position:fixed;
	top:70px;
	right:-1000px;
	z-index:999;
	justify-content:flex-end;
	align-items:center;
	width:100%;
	height:100%;
	margin:0;
	padding:20px 20px 80px 20px;
	list-style:none;
	background-color:var(--gray-1);
	transition:right 0.1s ease;

	@media (--header-break) {
		display:flex;
		position:static;
		padding:0 10px;
		background-color:transparent;
	}

	@media (--lg) {
		padding:0 30px;
	}
}
.c-header__nav-list {
	padding-top:48px;
	text-align:center;

	@media (--header-break) {
		padding-top:0;
	}
}
.c-header__nav-list.is-open {
	right:0;
	transition:right 0.1s ease;
}
.c-header__nav-item:not(:last-of-type) {
	margin-bottom:4px;

	@media (--header-break) {
		margin:0;
	}
}
.c-header__nav-item a {
	display:inline-block;
	position:relative;
	padding:14px 16px;
	color:var(--white);
	font-size:1.375rem;
	text-decoration:none;
	white-space:nowrap;

	@media (--header-break) {
		padding:4px 12px;
		font-size:0.875rem;
		text-align:center;
	}

	@media (--md) {
		font-size:0.9375rem;
	}

	@media (--lg) {
		padding:6px 16px;
		font-size:1rem;
	}
}
.c-header__nav-item a:visited {
	color:var(--white);
}
.c-header__nav-item a:active,
.c-header__nav-item a:focus,
.c-header__nav-item a:hover {
	color:var(--white);
	text-decoration:underline;
}
.c-header__nav-item--selected a {
	font-weight:600;
	text-decoration:underline;
}
.c-header__nav-toggle {
	width:24px;
	height:100%;
	margin-top:1px;
	margin-left:6px;
	padding:0;
	border:none;
	background-color:transparent;
	cursor:pointer;

	@media (--header-break) {
		display:none;
	}
}
.c-header__nav-toggle:active,
.c-header__nav-toggle:focus {
	outline:none;
}
.c-header__nav-bar,
.c-header__nav-line {
	display:block;
	width:100%;
	height:2px;
	margin-bottom:7px;
	background:var(--conch);
}
.c-header__nav-bar:nth-child(2) {
	width:70%;
	margin-left:auto;
}
.c-header__nav-line {
	position:absolute;
	bottom:4px;
	margin:0;
	transform:rotate(45deg);
}
.c-header__nav-line:last-of-type {
	transform:rotate(-45deg);
}
.c-header__nav-bar:last-of-type {
	margin:0;
}
.c-header__nav-cross {
	display:none;
	position:relative;
}
.c-header__nav-toggle[aria-expanded="true"] .c-header__nav-burger {
	display:none;
}
.c-header__nav-toggle[aria-expanded="true"] .c-header__nav-cross {
	display:block;
}
.c-header__drop-toggle[class] {
	padding:2px 8px;
	border:none;
	background-color:transparent;
	cursor:pointer;
}
.c-header__drop-toggle[class]:last-child {
	margin-right:8px;
}
.c-header__drop-toggle[class]:focus,
.c-header__drop-toggle[class]:hover,
.c-header__drop-toggle[class]:active {
	outline:none;
}
.c-header__drop-toggle[class] svg {
	fill:var(--conch);
}
.c-header__drop-toggle[data-hide="small"] {
	display:none;

	@media (--header-break) {
		display:inline-block;
	}
}
.c-header__drop-content {
	display:none;
	position:absolute;
	top:calc(100% + 10px);
	right:0;
	z-index:1000;
	width:280px;
	padding:18px;
	border-radius:3px;
	box-shadow:0 4px 10px rgba(0, 0, 0, 0.15);
	background:var(--white);
}
.c-header__drop-content > * {
	max-width:220px;
}
.c-header__drop-close {
	position:absolute;
	top:6px;
	right:8px;
	padding:0;
	border:none;
	background-color:transparent;
	cursor:pointer;
}
.c-header__drop-close svg {
	fill:var(--fuscous);
}
.c-header__drop.is-open .c-header__drop-content {
	display:block;
}
.c-header__drop-list {
	margin:0;
	padding:0;
	list-style:none;
}
.c-header__drop li {
	padding:8px 12px;
}
.c-header__drop a {
	text-decoration:none;
}
.c-header__search {
	display:flex;
	margin:0;
}
.c-header__search button {
	min-width:auto;
	margin-left:10px;
}
#main {
	margin-top:145px;

	@media (--md-max) {
		margin-top:100px;
	}
}

.main-startpage {
	margin-top: 95px !important;
}