/* =============================================================================
 * Quicks
 *
 * postcss-bem-linter: define quicks
============================================================================= */
.c-quicks {
	background-color:var(--block-bg);
	color:var(--text);
}
.c-quicks__list {
	display:flex;
	justify-content:space-around;
	flex-wrap:wrap;
	list-style:none;
}
.c-quicks__item {
	width:100%;
	margin-bottom:30px;
	text-align:center;

	@media (--xs) {
		max-width:calc(50% - 20px);
	}

	@media (--sm) {
		max-width:calc(33% - 20px);
	}

	@media (--lg) {
		max-width:calc(20% - 20px);
	}
}
.c-quicks__title {
	margin:0;
	margin-bottom:6px;
	font-weight:normal;
	font-size:var(--type-sz-20);
	line-height:var(--type-lh-20);
	font-family:var(--type-ff-default);
}
.c-quicks__content {
	margin:0;
	font-size:var(--type-sz-100);
	line-height:var(--type-lh-100);
	font-family:var(--type-ff-header);
}
