/* =============================================================================
 * Message
 *
 * postcss-bem-linter: define message
============================================================================= */
.c-message {
	padding:10px 0;
	background-color:var(--calypso);
	text-align:center;

	@media (--sm) {
		padding:20px 0;
	}
}
.c-message__text {
	color:var(--white);
}
.c-message__text a,
.c-message__text a:visited {
	color:var(--hippie);
	text-decoration:none;
}
.c-message__text a:hover,
.c-message__text a:active {
	text-decoration:underline;
}
.c-message__form {
	margin-bottom:0;
}
.c-message__button {
	background-color:var(--success);
}
