/* =============================================================================
 * Form-block
 *
 * postcss-bem-linter: define form-block
============================================================================= */
.c-form-block {
	background:var(--block-bg);
	color:var(--text);
	scroll-margin-top:200px;
}
.c-form-block__content {
	max-width:500px;
	margin:0 auto;
	text-align:center;
}
.c-form-block--image.c-form-block {
	position:relative;
	min-height:300px;
}
.c-form-block--image .c-form-block__inner {
	@media (--sm) {
		display:flex;
		align-items:center;
	}
}
.c-form-block--image .c-form-block__content {
	@media (--sm) {
		max-width:none;
		width:60%;
		margin:0;
		text-align:left;
	}
}
.c-form-block--image .c-form-block__image {
	display:none;
	justify-content:flex-end;
	width:40%;

	@media (--sm) {
		display:flex;
	}
}
.c-form-block--image .c-form-block__image img {
	position:absolute;
	bottom:0;
	max-width:25%;

	@media (--md) {
		max-width:100%;
	}
}
.c-form-block p {
	font-size:var(--type-sz-20);
	line-height:var(--type-lh-20);
}
.c-form-block__form {
	margin:0 0 15px 0;
}
.c-form-block__control {
	position:relative;
	max-width:300px;
	margin:0 auto 15px auto;
}
.c-form-block--image .c-form-block__control {
	margin:0 0 15px;
}
.c-form-block input {
	padding:18px 10px 8px 10px;
}
.c-form-block input.has-error {
	border:1px solid var(--error);
}
.c-form-block__error {
	display:block;
	color:var(--error);
	font-size:0.75rem;
	text-align:left;
}
.c-form-block__label {
	position:absolute;
	top:14px;
	left:11px;
	color:var(--gray-1);
	transition:0.2s ease all;
	pointer-events:none;
}
.c-form-block input:focus ~ .c-form-block__label,
.c-form-block input:not(:focus):valid ~ .c-form-block__label {
	top:2px;
	bottom:10px;
	font-size:var(--type-sz-5);
	line-height:var(--type-lh-5);
	opacity:1;
}
.c-form-block__privacy[class] {
	margin-top:12px;
	font-size:var(--type-sz-5);
	line-height:var(--type-lh-5);
}
.c-form-block__privacy[class] a {
	color:var(--text-link);
}
.c-form-block--image-background {
	position:relative;
}
.c-form-block__background {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	background-position:50%;
	background-size:cover;
	background-repeat:no-repeat;
	opacity:0.25;
}
.c-form-block--image-background .c-form-block__inner {
	position:relative;
	z-index:2;
}
.c-form-block__messages .c-form-block__success,
.c-form-block__messages .c-form-block__error {
	display:none;
}
.c-form-block__messages .c-form-block__error {
	margin:0 auto;
	padding:10px;
	border-radius:5px;
	background-color:var(--error);
	color:var(--white);
	font-size:inherit;
	text-align:center;
}

/* Compact form block */
.c-form-block--compact .c-form-block__form {
	@media (--md) {
		display:flex;
		flex-direction:row;
		align-items:center;
		margin:0;
	}
}
.c-form-block--compact .c-form-block__content {
	max-width:none;
	text-align:unset;
}
.c-form-block--compact .c-form-block__title {
	width:100%;
	margin-bottom:10px;
}
.c-form-block--compact .c-form-block__control {
	max-width:none;

	@media (--md) {
		margin:0 20px 0 0;
	}
}
.c-form-block--compact .c-form-block__control--grow {
	flex-grow:1;
}
.c-form-block--compact .c-form-block__control input {
	min-width:200px;
}
.c-form-block--compact .c-form-block__privacy[class] {
	margin:6px 0 0;
	text-align:right;

	@media (--sm) {
		margin:12px 0 0;
	}
}

/* Grid form block */
.c-form-block--grid .c-form-block__form {
	display:grid;
	grid-template-columns:repeat(1, 1fr);
	row-gap:24px;

	@media (--md) {
		grid-gap:24px;
		grid-template-columns:repeat(4, 1fr);
	}
}
.c-form-block--grid .c-form-block__content {
	max-width:none;

	@media (--md) {
		text-align:left;
	}
}
.c-form-block--grid .c-form-block__title {
	@media (--md) {
		text-align:left;
	}
}
.c-form-block--grid .c-form-block__control {
	max-width:none;
	margin:0;

	@media (--md-max) {
		justify-self:center;
		max-width:300px;
		width:100%;
	}
}
.c-form-block--grid .c-form-block__control--double {
	@media (--md) {
		grid-column:span 2;
	}
}
.c-form-block--grid button {
	justify-self:center;

	@media (--md) {
		align-self:end;
		justify-self:end;
		grid-column:span 2;
	}
}
