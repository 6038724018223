/* =============================================================================
 * Excerpt
 *
 * postcss-bem-linter: define excerpt
============================================================================= */
.c-excerpt {
	display:flex;
	position:relative;
	align-items:flex-start;
}
.c-excerpt--compact .c-excerpt__media {
	@media (--sm) {
		width:33%;
	}
}
.c-excerpt--compact .c-excerpt__title {
	margin-top:0;
}
.c-excerpt--vertical.c-excerpt {
	@media (--sm-max) {
		flex-wrap:wrap;
	}
}
.c-excerpt__media {
	position:absolute;
	right:0;
	flex-shrink:0;
	width:30%;

	@media (--sm) {
		position:static;
		width:40%;
		margin-right:30px;
	}
}
.c-excerpt--vertical .c-excerpt__media {
	@media (--sm-max) {
		position:static;
		right:unset;
		flex-shrink:unset;
		width:unset;
		margin-bottom:20px;
	}
}
.c-excerpt__media img {
	margin:0;
	border-radius:5px;
	box-shadow:0 4px 10px rgba(0, 0, 0, 0.15);
}
.c-excerpt__content {
	max-width:60%;

	@media (--sm) {
		max-width:none;
	}
}
.c-excerpt--vertical .c-excerpt__content {
	@media (--sm-max) {
		max-width:none;
	}
}
.c-excerpt--full .c-excerpt__content {
	max-width:none;
}
.c-excerpt__title {
	margin:0 0 10px 0;
	font-size:var(--type-sz-60);
	line-height:var(--type-lh-60);
	font-family:var(--type-ff-header);

	@media (--md) {
		margin-top:15px;
	}
}
.c-excerpt__title a {
	font-family:inherit;
	text-decoration:none;
}
.c-excerpt__title a:hover {
	text-decoration:underline;
}
.c-excerpt__meta {
	color:#666;
	color:var(--gray-90);
	font-size:var(--type-sz-10);
	line-height:var(--type-lh-10);
}
.c-excerpt__content p {
	max-width:var(--limiter-slim);
	margin-bottom:10px;
}
.c-excerpt__content p:last-child {
	margin-bottom:0;
}
