/* =============================================================================
 * Cookie consent
 *
 * postcss-bem-linter: define cookieconsent
============================================================================= */
.cookieconsent-link {
	position:fixed;
	bottom:1rem;
	left:1rem;
	z-index:100;
	width:40px;
	height:40px;
	transition:all 0.3s;
}
.cookieconsent-link:hover {
	transform:scale(1.2);
}
.bg-white {
	background-color:#fff;
}
.rounded-circle {
	border-radius:50% !important;
}
.shadow-sm {
	box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.alert {
	position:relative;
	margin-bottom:1rem;
	padding:0.75rem 1.25rem;
	border:1px solid transparent;
	border-radius:0.25rem;
}
.alert-danger {
	border-color:#f5c6cb;
	background-color:#f8d7da;
	color:#721c24;
}
.CookieConsent legend {
	float:left;
	width:100%;
	margin-bottom:0.5rem;
	padding:0;
	font-size:1.25em;
	line-height:inherit;
}
.CookieConsent label {
	display:inline-block;
	font-weight:normal;
	font-family: "AvenirRegular", Arial, sans-serif;
}
.CookieConsent input[type=checkbox],
.CookieConsent select[type=checkbox] {
	width:21px;
	height:21px;
	margin-top:1px;
	vertical-align:top;
}
.CookieConsent input[type=checkbox]:disabled,
.CookieConsent select[type=checkbox]:disabled {
	opacity:0.5;
	filter:none;
	pointer-events:none;
}
.CookieConsent input[type=checkbox]:checked,
.CookieConsent select[type=checkbox]:checked {
	background-position:center 3px;
	background-size:14px;
}
.CookieConsent .backdrop {
	position:fixed !important;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:300;
	max-width:none;
	overflow-y:auto;
	padding:0 1.25rem;
	border:none;
	background-color:rgba(0, 0, 0, 0.76);
	font-family: "AvenirRegular", Arial, sans-serif;
}
.CookieConsent .cookie-modal {
	position:relative;
	z-index:1;
	max-width:796px;
	margin:3.75rem auto 1.25rem;
	padding:70px 60px 93px 60px;
	border-radius:4px;
	background-color:#fff;
	color:#000;
	font-family: "AvenirRegular", Arial, sans-serif;
}

@media only screen and (--md-max) {
	.CookieConsent .cookie-modal {
		margin-top:60px;
	}
}

@media only screen and (--xs-max) {
	.CookieConsent .cookie-modal {
		padding-bottom:160px;
	}
}
.CookieConsent .cookie-modal header {
	margin-bottom:1rem;
	font-weight:bold;
	font-family: "AvenirRegular", Arial, sans-serif;
}
.CookieConsent .cookie-modal fieldset {
	margin:0;
	margin-bottom:20px;
	padding:0.625rem;
	border:0;
	background:#eee;
}
.CookieConsent .cookie-modal em {
	font-weight:bold;
	font-style:normal;
	font-family: "AvenirRegular", Arial, sans-serif;
}
.CookieConsent .buttons {
	float:right;
}
.CookieConsent .buttons button {
	margin-bottom:20px;
}
.CookieConsent .form-check-input {
	margin-right:0.5rem;
}
.CookieConsent .c-button {
	margin-bottom:15px;
	margin-left:30px;
	padding:11px 18px 11px 19px;
	border:none;
	border-radius:3px;
	background-color:#2f688a;
	color:#fff;
	font-weight:700;
	text-decoration:none;
	cursor:pointer;
	-webkit-appearance:none;
	appearance:none;
	font-family: "AvenirRegular", Arial, sans-serif;
}
.CookieConsent .c-button.button-blue {
	border:1px solid #333;
	background-color:#fff;
	color:#333;
}


