/* =============================================================================
 * Containers
 *
 * postcss-bem-linter: define container
============================================================================= */
.o-container {
	--base:12px;
}

/* zigzag */
.o-container--zig-zag[class] {
	padding:calc(var(--base) * 8) 0;
	background-color:var(--block-bg);
	color:var(--text);
}
.o-container--zig-zag + .o-container--zig-zag {
	margin-top:calc(var(--base) * -4);
	padding-top:0;
}
