/* =============================================================================
 * Facts
 * postcss-bem-linter: define facts
============================================================================= */
.c-facts {
	position:relative;
	background:var(--block-bg);
	color:var(--text);
	--col-gap:50px;

	@media (--sm) {
		--col-gap:80px;
	}
}
.c-facts__title {
	margin-bottom:32px;
}
.c-facts__list {
	display:grid;
	position:relative;
	grid-row-gap:30px;
	grid-template-columns:1fr;
	width:100%;
	margin:0;
	padding:0;
	list-style:none;
	font-size:var(--type-sz-40);
	line-height:var(--type-lh-40);

	@media (--xs) {
		grid-column-gap:var(--col-gap);
		grid-template-columns:1fr 1fr;
	}

	@media (--sm) {
		left:5%;
		width:95%;
	}

	@media (--md) {
		grid-template-columns:1fr 1fr 1fr;
	}
}
.c-facts__heading {
	margin:0 0 8px 0;
	font-weight:700;
}
.c-facts--heading-serif .c-facts__heading {
	font-family:var(--type-ff-header);
}
.c-facts__content {
	margin:0;
}
.c-facts--heading-serif .c-facts__content {
	font-size:var(--type-sz-default);
}
.c-facts--divider .c-facts__item:nth-of-type(-n+3)::before {
	position:absolute;
	top:4px;
	width:1px;
	height:calc(100% - 12px);
	background-color:var(--icon-subtle);
	content:"";
}
.c-facts--divider .c-facts__heading,
.c-facts--divider .c-facts__content {
	margin-left:40px;
}
