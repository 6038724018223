/* =============================================================================
 * Breadcrumbs
 * postcss-bem-linter: define breadcrumbs
============================================================================= */
.c-breadcrumbs {
	font-size:var(--type-sz-10);
}
.c-breadcrumbs__inner {
	position:relative;
}

@media (max-width: 991px) {
	.c-breadcrumbs__content {
		top: 25px;
	}
}

.c-breadcrumbs__item {
	text-decoration:underline;
}

.c-breadcrumbs__level[class],
.c-breadcrumbs__separator,
.c-breadcrumbs__current-page {
	color:var(--black);
	text-decoration:none;
}

.c-breadcrumbs__level[class]:hover {
	text-decoration:underline;
}
.c-breadcrumbs--inverted .c-breadcrumbs__level[class],
.c-breadcrumbs--inverted .c-breadcrumbs__separator,
.c-breadcrumbs--inverted .c-breadcrumbs__current-page {
	color:var(--black);
}

.c-breadcrumbs--above-hero {
	.c-breadcrumbs__inner {
		.c-breadcrumbs__content {
			position: relative;
			top: 14px;
			margin-bottom: 11px;
			left: 0;
		}
	}
}

@media (max-width: 990px) {
	.c-breadcrumbs--above-hero {
		.c-breadcrumbs__inner {
			.c-breadcrumbs__content {
				top: 14px;
			}
		}
	}
}
