/* =============================================================================
 * Flex justify
 *
 * postcss-bem-linter: define flex-justify
============================================================================= */
.t-flex-justify--start {
	justify-content:start;
}
.t-flex-justify--end {
	justify-content:end;
}
.t-flex-justify--center {
	justify-content:center;
}
.t-flex-justify--stretch {
	justify-content:stretch;
}
.t-flex-justify--baseline {
	justify-content:baseline;
}
