/* =============================================================================
 * Excerpt
 *
 * postcss-bem-linter: define excerpt
============================================================================= */
.c-main-nav--overlay {
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:100;
	height:calc(100vh + 95px);

	@media (--sm) {
		background-color:rgba(0, 0, 0, 0.1);
		transition:all 0.8s;
	}
}
.c-main-nav__toggle {
	display:inline-block;
	width:24px;
	height:100%;
	margin-top:1px;
	margin-left:6px;
	padding:0;
	border:none;
	background-color:transparent;
	cursor:pointer;
}
.c-main-nav__nav-bar,
.c-main-nav__nav-line {
	display:block;
	width:100%;
	height:2px;
	margin-bottom:7px;
	background:var(--black);
}
.c-main-nav__nav-bar:nth-child(2) {
	width:70%;
	margin-left:auto;
}
.c-main-nav__nav-line {
	position:absolute;
	bottom:4px;
	margin:0;
	transform:rotate(45deg);
}
.c-main-nav__nav-line:last-of-type {
	transform:rotate(-45deg);
}
.c-main-nav__nav-bar:last-of-type {
	margin:0;
}
.c-main-nav__nav-cross {
	display:none;
	position:relative;
}
.c-main-nav__nav-toggle[aria-expanded="true"] .c-main-nav__nav-burger {
	display:none;
}
.c-main-nav__nav-toggle[aria-expanded="true"] .c-main-nav__nav-cross {
	display:block;
}
.c-main-nav__content {
	position:fixed;
	top:68px;
	right:0;
	bottom:0;
	left:0;
	z-index:5;
	height:100vh;
	overflow-x:hidden;
	background-color:#fafafa;
	padding-bottom:70px;

	@media (--sm) {
		top:0;
		left:auto;
		width:574px;
	}
}

/* .c-main-nav__content::before,
.c-main-nav__content::after {
	position:fixed;
	top:0;
	right:24px;
	z-index:4;
	width:100%;
	height:40px;
	background-image:linear-gradient(to top, rgba(247, 245, 243, 0), var(--pearl-15));
	content:"";

	@media (--sm) {
		width:380px;
	}
} */
.c-main-nav__content::after {
	top:auto;
	bottom:0;
	background-image:linear-gradient(to bottom, rgba(247, 245, 243, 0), var(--pearl-15));
}
.c-main-nav__close {
	position:fixed;
	top:20px;
	right:12px;
	left:auto;
	width:32px;
	height:32px;
	border:none;
	border-radius:3px;
	background-color:transparent;
	cursor:pointer;

	@media (--sm) {
		position:static;
	}
}
.c-main-nav__close:hover {
	background-color:var(--fuscous-15);
}
.c-main-nav__close svg {
	fill:var(--fuscous);
}
.c-main-nav__inner {
	position:relative;
	padding:24px 0;
}
.c-main-nav__content::-webkit-scrollbar-track {
	background-color:transparent;
}
.c-main-nav__content::-webkit-scrollbar {
	width:6px;
	background-color:transparent;
}
.c-main-nav__content::-webkit-scrollbar-thumb {
	border-radius:10px;
	background-color:var(--fuscous-50);
}
.c-main-nav .toggle-enter-active,
.c-main-nav .toggle-leave-active {
	@media (--sm) {
		transition:transform 0.8s;
		transform:translateX(0);
	}
}
.c-main-nav .toggle-enter,
.c-main-nav .toggle-leave-to {
	@media (--sm) {
		transform:translateX(420px);
	}
}
.c-main-nav__sticky-top {
	z-index:10;
	background-color:#fafafa;

	@media (--sm) {
		position:-webkit-sticky;
		position:sticky;
		top:0;
	}
}


.c-main-nav__search {
	display: flex;
	top: 117px;
	z-index: 10;
	border-top: 1px solid #f1eeeb;
	background-color: white;

	@media (--sm) {
		position: -webkit-sticky;
		position: sticky;
		top:90px;
	}
}

.c-mobile-nav__search {
	display: flex;
	top: 145px;
	background-color: white;
	margin-right: 20px;
	margin-top: 30px;
	width: 85%;

	@media (--sm) {
		position: -webkit-sticky;
		position: sticky;
		top:90px;
	}
}

.c-mobile-nav__search {
	padding:40px 32px 30px;

	@media (--sm) {
		padding:30px 90px;
	}
}
.c-mobile-nav__search input {
	padding: 10px;
	padding-left: 20px;
	border: 1px solid #666;
	border-right: none;
	border-radius: 30px 0 0 30px;
	background-color: white;
}
.c-mobile-nav__search button {
	width: 58px;
	border: 1px solid #666;
	border-radius: 0 30px 30px 0;
	background-color: white;
}

.c-mobile-nav__search-icon {
	margin-right: 12px;
	fill: #666;
	margin-bottom: 2px;
}

@media (max-width: 767px) {
	.c-main-nav__search {
		height: 120px;
	}
}

@media (--md-max) {
	.c-main-nav__search {
		top: 0;
	}
}

.c-main-nav__change-lang-top-container {
	display:flex;
	justify-content:space-between;
	align-items:center;
	height:70px;
	margin-top:2px;
	padding:0 32px;
	border-top:1px solid #f1eeeb;

	@media (--sm) {
		margin-top:0;
		padding:0 100px;
		border-top:none;
		border-bottom: 2px solid #f1eeeb;
	}

	@media (--md) {
		height:97px;
	}

	@media (--md-max) {
		height: 70px;
	}
}
.c-main-nav__change-lang-container {
	display:flex;

}
.c-main-nav__change-lang-block {
	margin-right:48px;
	display: flex;
}
.c-main-nav__change-lang-block a {
	color:var(--fuscous);
	font-size:12px;
	text-decoration:none;
}

.language-link-text {
	text-transform: uppercase !important;
	color:var(--fuscous);
	font-size:12px;
	text-decoration:none;
}

.language-link-slash {
	margin-right: 3px;
}

.c-main-nav__lang-icon {
	margin-right:12px;
	fill:var(--fuscous);
}
.c-main-nav__search {
	padding:40px 32px 30px;

	@media (--sm) {
		padding:30px 90px;
	}
}
.c-main-nav__search input {
	padding: 10px;
	padding-left: 35px;
	border:1px solid #666;
	border-right:none;
	border-radius:30px 0 0 30px;
	background-color: transparent;
}
.c-main-nav__search button {
	width:58px;
	border:1px solid #666;
	border-radius:0 30px 30px 0;
	background-color:transparent;
}
.c-main-nav__search-icon {
	margin-right:12px;
	fill:#666;
	margin-bottom: 2px;
}
.c-main-nav__close-icon {
	fill:black;
	margin-bottom: 5px;
}

.c-main-nav__plus-icon {
	width:12px;
	fill:var(--fuscous);

}
.c-main-nav__item-container {
	/* border-bottom:1px solid #b3c3bd; */
}
.c-main-nav__item {
	display:flex;
	flex-direction:column;
	align-items:center;
}
.c-main-nav__item-row {
	display:flex;
	justify-content:space-between;
	width:100%;
}
.c-main-nav__link {
	display:flex;
	align-items:center;

	/* padding:16px 0; */
	color:inherit;
	text-decoration:none;
}
.c-main-nav__link-container {
	margin:0 32px;
	border-bottom:1px solid #b3c3bd;

	@media (--sm) {
		margin:0 102px;
	}
}

/* .c-main-nav__item-container:nth-child(2) {
margin-left: 30px;
} */
.c-main-nav__bottom-link-container {
	margin:58px 0 132px;
}
.c-main-nav__item-bottom {
	margin-left:52px;
	padding:12px 0;

	@media (--sm) {
		margin-left:102px;
	}
}
.c-main-nav__item-bottom-link {
	color:#666;
	font-size:14px;
	text-decoration:none;
}
.c-main-nav__item-bottom-icon {
	width:16px;
	margin-right:12px;
	fill:var(--fuscous);
}
.c-main-nav__editeble-text {
	margin:58px 0;
	color:#666;
	font-size:12px;
	text-align:center;
}
.c-main-nav__expand-item-button {
	padding:16px;

	/* background-color: transparent; */
	border:none;
	background-color:transparent;
	cursor:pointer;
}
.c-main-nav__item-row-border-bottom {
	position:relative;
	margin-bottom:4px;
}
.c-main-nav__item-row-border-bottom::after {
	position:absolute;
	bottom:0;
	left:0;
	width:44px;
	height:1px;
	background:var(--black);
	content:"";
}
.c-main-nav__item-row-expanded {
	margin-left:16px;
}
