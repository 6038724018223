/* =============================================================================
 * File icons
 *
 * postcss-bem-linter: define file-icons
============================================================================= */
a[href$=".pdf"]::before,
a[href$=".doc"]::before,
a[href$=".docx"]::before,
a[href$=".xls"]::before,
a[href$=".xlsx"]::before,
a[href$=".jpg"]::before,
a[href$=".jpeg"]::before,
a[href$=".png"]::before,
a[href$=".gif"]::before,
a[href$=".txt"]::before,
a[href$=".zip"]::before,
a[href*="//"]:not([href*="wihlborgs.se"])::before {
	display:none;
	position:absolute;
	top:-2px;
	left:2px;
	width:16px;
	height:16px;
	background-color:currentColor;
	content:"";
	-webkit-mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V5h-2.5A1.5 1.5 0 0 1 9 3.5V1H2.5zM14 14.5a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 14.5v-13A1.5 1.5 0 0 1 2.5 0h7.586a1.5 1.5 0 0 1 1.06.44l2.415 2.414A1.5 1.5 0 0 1 14 3.914V14.5zM12.854 3.56a.5.5 0 0 1 .146.354V4h-2.5a.5.5 0 0 1-.5-.5V1h.086a.5.5 0 0 1 .353.146l2.415 2.415z' fill='currentColor'/></svg>");
	mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V5h-2.5A1.5 1.5 0 0 1 9 3.5V1H2.5zM14 14.5a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 14.5v-13A1.5 1.5 0 0 1 2.5 0h7.586a1.5 1.5 0 0 1 1.06.44l2.415 2.414A1.5 1.5 0 0 1 14 3.914V14.5zM12.854 3.56a.5.5 0 0 1 .146.354V4h-2.5a.5.5 0 0 1-.5-.5V1h.086a.5.5 0 0 1 .353.146l2.415 2.415z' fill='currentColor'/></svg>");
	-webkit-mask-repeat:no-repeat;
	mask-repeat:no-repeat;
	-webkit-mask-position:center;
	mask-position:center;
}

/* PDF */
a[href$=".pdf"]::before {
	-webkit-mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.5 1a.5.5 0 0 0-.5.5V6h11V5h-2.5A1.5 1.5 0 0 1 9 3.5V1H2.5zM14 6h2v7h-2v1.5a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 14.5v-13A1.5 1.5 0 0 1 2.5 0h7.586a1.5 1.5 0 0 1 1.06.44l2.415 2.414A1.5 1.5 0 0 1 14 3.914V6zm-1 7H2v1.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V13zm0-9.086V4h-2.5a.5.5 0 0 1-.5-.5V1h.086a.5.5 0 0 1 .353.146l2.415 2.415a.5.5 0 0 1 .146.353zM4 7v5h1v-2h1a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H4zm2 2H5V8h1v1zm2-2v5h1.5a1.5 1.5 0 0 0 1.5-1.5v-2A1.5 1.5 0 0 0 9.5 7H8zm1.5 4H9V8h.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5zM15 7v1h-2v1h1.5v1H13v2h-1V7h3z' fill='currentColor'/></svg>");
	mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.5 1a.5.5 0 0 0-.5.5V6h11V5h-2.5A1.5 1.5 0 0 1 9 3.5V1H2.5zM14 6h2v7h-2v1.5a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 14.5v-13A1.5 1.5 0 0 1 2.5 0h7.586a1.5 1.5 0 0 1 1.06.44l2.415 2.414A1.5 1.5 0 0 1 14 3.914V6zm-1 7H2v1.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V13zm0-9.086V4h-2.5a.5.5 0 0 1-.5-.5V1h.086a.5.5 0 0 1 .353.146l2.415 2.415a.5.5 0 0 1 .146.353zM4 7v5h1v-2h1a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H4zm2 2H5V8h1v1zm2-2v5h1.5a1.5 1.5 0 0 0 1.5-1.5v-2A1.5 1.5 0 0 0 9.5 7H8zm1.5 4H9V8h.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5zM15 7v1h-2v1h1.5v1H13v2h-1V7h3z' fill='currentColor'/></svg>");
}

/* Images */
a[href$=".jpg"]::before,
a[href$=".jpeg"]::before,
a[href$=".png"]::before {
	-webkit-mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M6 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM5 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0z' fill='currentColor'/><path fill-rule='evenodd' clip-rule='evenodd' d='M1 0a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 1H1v11.2l3.037-3.796a1.5 1.5 0 0 1 2.003-.311l1.38.92a.5.5 0 0 0 .702-.151l1.118-1.79a1.5 1.5 0 0 1 2.534-.015L15 12.075V1zm-.594 12H1.64l3.178-3.972a.5.5 0 0 1 .667-.103l1.38.92a1.5 1.5 0 0 0 2.105-.453l1.118-1.79a.5.5 0 0 1 .845-.005L14.405 13z' fill='currentColor'/></svg>");
	mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M6 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM5 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0z' fill='currentColor'/><path fill-rule='evenodd' clip-rule='evenodd' d='M1 0a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 1H1v11.2l3.037-3.796a1.5 1.5 0 0 1 2.003-.311l1.38.92a.5.5 0 0 0 .702-.151l1.118-1.79a1.5 1.5 0 0 1 2.534-.015L15 12.075V1zm-.594 12H1.64l3.178-3.972a.5.5 0 0 1 .667-.103l1.38.92a1.5 1.5 0 0 0 2.105-.453l1.118-1.79a.5.5 0 0 1 .845-.005L14.405 13z' fill='currentColor'/></svg>");
}

/* Word */
a[href$=".doc"]::before,
a[href$=".docx"]::before {
	-webkit-mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.5 1a.5.5 0 0 0-.5.5V6h11V5h-2.5A1.5 1.5 0 0 1 9 3.5V1H2.5zM14 6h2v7h-2v1.5a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 14.5v-13A1.5 1.5 0 0 1 2.5 0h7.586a1.5 1.5 0 0 1 1.06.44l2.415 2.414A1.5 1.5 0 0 1 14 3.914V6zm-1 7H2v1.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V13zm0-9.086V4h-2.5a.5.5 0 0 1-.5-.5V1h.086a.5.5 0 0 1 .353.146l2.415 2.415a.5.5 0 0 1 .146.353zM4 12V7h1.5A1.5 1.5 0 0 1 7 8.5v2A1.5 1.5 0 0 1 5.5 12H4zm1-1h.5a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5H5v3zm3-3v3a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1zm2 3H9V8h1v3zm2-3v3a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-.75h-1V11h-1V8h1v.75h1V8a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1z' fill='currentColor'/></svg>");
	mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2.5 1a.5.5 0 0 0-.5.5V6h11V5h-2.5A1.5 1.5 0 0 1 9 3.5V1H2.5zM14 6h2v7h-2v1.5a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 14.5v-13A1.5 1.5 0 0 1 2.5 0h7.586a1.5 1.5 0 0 1 1.06.44l2.415 2.414A1.5 1.5 0 0 1 14 3.914V6zm-1 7H2v1.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V13zm0-9.086V4h-2.5a.5.5 0 0 1-.5-.5V1h.086a.5.5 0 0 1 .353.146l2.415 2.415a.5.5 0 0 1 .146.353zM4 12V7h1.5A1.5 1.5 0 0 1 7 8.5v2A1.5 1.5 0 0 1 5.5 12H4zm1-1h.5a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5H5v3zm3-3v3a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1zm2 3H9V8h1v3zm2-3v3a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-.75h-1V11h-1V8h1v.75h1V8a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1z' fill='currentColor'/></svg>");
}

/* Txt */
a[href$=".txt"]::before {
	-webkit-mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M14 6V3.914a1.5 1.5 0 0 0-.44-1.06L11.147.439A1.5 1.5 0 0 0 10.086 0H2.5A1.5 1.5 0 0 0 1 1.5v13A1.5 1.5 0 0 0 2.5 16h10a1.5 1.5 0 0 0 1.5-1.5V13h2V6h-2zM2 1.5a.5.5 0 0 1 .5-.5H9v2.5A1.5 1.5 0 0 0 10.5 5H13v1H2V1.5zM2 13v1.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V13H2zm8.5-9a.5.5 0 0 1-.5-.5V1h.086a.5.5 0 0 1 .353.146l2.415 2.415a.5.5 0 0 1 .146.353V4h-2.5zM7 7H4v1h1v4h1V8h1V7zm4 1.25V7h-1v1.25a.5.5 0 0 1-.2.4l-.3.225-.3-.225a.5.5 0 0 1-.2-.4V7H8v1.25c0 .472.222.917.6 1.2l.067.05-.067.05c-.378.283-.6.728-.6 1.2V12h1v-1.25a.5.5 0 0 1 .2-.4l.3-.225.3.225a.5.5 0 0 1 .2.4V12h1v-1.25a1.5 1.5 0 0 0-.6-1.2l-.067-.05.067-.05a1.5 1.5 0 0 0 .6-1.2zM15 7v1h-1v4h-1V8h-1V7h3z' fill='currentColor'/></svg>");
	mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M14 6V3.914a1.5 1.5 0 0 0-.44-1.06L11.147.439A1.5 1.5 0 0 0 10.086 0H2.5A1.5 1.5 0 0 0 1 1.5v13A1.5 1.5 0 0 0 2.5 16h10a1.5 1.5 0 0 0 1.5-1.5V13h2V6h-2zM2 1.5a.5.5 0 0 1 .5-.5H9v2.5A1.5 1.5 0 0 0 10.5 5H13v1H2V1.5zM2 13v1.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V13H2zm8.5-9a.5.5 0 0 1-.5-.5V1h.086a.5.5 0 0 1 .353.146l2.415 2.415a.5.5 0 0 1 .146.353V4h-2.5zM7 7H4v1h1v4h1V8h1V7zm4 1.25V7h-1v1.25a.5.5 0 0 1-.2.4l-.3.225-.3-.225a.5.5 0 0 1-.2-.4V7H8v1.25c0 .472.222.917.6 1.2l.067.05-.067.05c-.378.283-.6.728-.6 1.2V12h1v-1.25a.5.5 0 0 1 .2-.4l.3-.225.3.225a.5.5 0 0 1 .2.4V12h1v-1.25a1.5 1.5 0 0 0-.6-1.2l-.067-.05.067-.05a1.5 1.5 0 0 0 .6-1.2zM15 7v1h-1v4h-1V8h-1V7h3z' fill='currentColor'/></svg>");
}

/* Excel */
a[href$=".xls"]::before,
a[href$=".xlsx"]::before {
	-webkit-mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2 1.5a.5.5 0 0 1 .5-.5H9v2.5A1.5 1.5 0 0 0 10.5 5H13v1H2V1.5zM13 4v-.086a.5.5 0 0 0-.146-.353l-2.415-2.415A.5.5 0 0 0 10.086 1H10v2.5a.5.5 0 0 0 .5.5H13zm3 2h-2V3.914a1.5 1.5 0 0 0-.44-1.06L11.147.439A1.5 1.5 0 0 0 10.086 0H2.5A1.5 1.5 0 0 0 1 1.5v13A1.5 1.5 0 0 0 2.5 16h10a1.5 1.5 0 0 0 1.5-1.5V13h2V6zM2 13h11v1.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5V13zm5-4.75V7H6v1.25a.5.5 0 0 1-.2.4l-.3.225-.3-.225a.5.5 0 0 1-.2-.4V7H4v1.25c0 .472.222.917.6 1.2l.067.05-.067.05c-.378.283-.6.728-.6 1.2V12h1v-1.25a.5.5 0 0 1 .2-.4l.3-.225.3.225a.5.5 0 0 1 .2.4V12h1v-1.25a1.5 1.5 0 0 0-.6-1.2l-.067-.05.067-.05a1.5 1.5 0 0 0 .6-1.2zM9 11V7H8v5h3v-1H9zm6-4l-.25 1H13v1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-2l.25-1H14v-1h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h2z' fill='currentColor'/></svg>");
	mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M2 1.5a.5.5 0 0 1 .5-.5H9v2.5A1.5 1.5 0 0 0 10.5 5H13v1H2V1.5zM13 4v-.086a.5.5 0 0 0-.146-.353l-2.415-2.415A.5.5 0 0 0 10.086 1H10v2.5a.5.5 0 0 0 .5.5H13zm3 2h-2V3.914a1.5 1.5 0 0 0-.44-1.06L11.147.439A1.5 1.5 0 0 0 10.086 0H2.5A1.5 1.5 0 0 0 1 1.5v13A1.5 1.5 0 0 0 2.5 16h10a1.5 1.5 0 0 0 1.5-1.5V13h2V6zM2 13h11v1.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5V13zm5-4.75V7H6v1.25a.5.5 0 0 1-.2.4l-.3.225-.3-.225a.5.5 0 0 1-.2-.4V7H4v1.25c0 .472.222.917.6 1.2l.067.05-.067.05c-.378.283-.6.728-.6 1.2V12h1v-1.25a.5.5 0 0 1 .2-.4l.3-.225.3.225a.5.5 0 0 1 .2.4V12h1v-1.25a1.5 1.5 0 0 0-.6-1.2l-.067-.05.067-.05a1.5 1.5 0 0 0 .6-1.2zM9 11V7H8v5h3v-1H9zm6-4l-.25 1H13v1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-2l.25-1H14v-1h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h2z' fill='currentColor'/></svg>");
}

/* External */
a[href*="//"]:not([href*="wihlborgs.se"])::before {
	-webkit-mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M1 4a1 1 0 0 1 1-1h6.65v1H2v10h10V7h1v7a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4z' fill='currentColor'/><path fill-rule='evenodd' clip-rule='evenodd' d='M9.8 1.5a.5.5 0 0 1 .5-.5H15v4.7a.5.5 0 0 1-1 0V2.707L8.854 7.854a.5.5 0 1 1-.708-.708L13.293 2H10.3a.5.5 0 0 1-.5-.5z' fill='currentColor'/></svg>");
	mask-image:url("data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M1 4a1 1 0 0 1 1-1h6.65v1H2v10h10V7h1v7a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4z' fill='currentColor'/><path fill-rule='evenodd' clip-rule='evenodd' d='M9.8 1.5a.5.5 0 0 1 .5-.5H15v4.7a.5.5 0 0 1-1 0V2.707L8.854 7.854a.5.5 0 1 1-.708-.708L13.293 2H10.3a.5.5 0 0 1-.5-.5z' fill='currentColor'/></svg>");
}

/* =============================================================================
 * Components where they are used
============================================================================= */
.c-text__block a[href$=".pdf"],
.c-text__block a[href$=".doc"],
.c-text__block a[href$=".docx"],
.c-text__block a[href$=".xls"],
.c-text__block a[href$=".xlsx"],
.c-text__block a[href$=".jpg"],
.c-text__block a[href$=".jpeg"],
.c-text__block a[href$=".png"],
.c-text__block a[href$=".gif"],
.c-text__block a[href$=".txt"],
.c-text__block a[href$=".zip"],
.c-text__block a[href*="//"]:not([href*="wihlborgs.se"]),
.c-article a[href$=".pdf"],
.c-article a[href$=".doc"],
.c-article a[href$=".docx"],
.c-article a[href$=".xls"],
.c-article a[href$=".xlsx"],
.c-article a[href$=".jpg"],
.c-article a[href$=".jpeg"],
.c-article a[href$=".png"],
.c-article a[href$=".gif"],
.c-article a[href$=".txt"],
.c-article a[href$=".zip"],
.c-article a[href*="//"]:not([href*="wihlborgs.se"]),
.c-excerpt a[href$=".pdf"],
.c-excerpt a[href$=".doc"],
.c-excerpt a[href$=".docx"],
.c-excerpt a[href$=".xls"],
.c-excerpt a[href$=".xlsx"],
.c-excerpt a[href$=".jpg"],
.c-excerpt a[href$=".jpeg"],
.c-excerpt a[href$=".png"],
.c-excerpt a[href$=".gif"],
.c-excerpt a[href$=".txt"],
.c-excerpt a[href$=".zip"],
.c-excerpt a[href*="//"]:not([href*="wihlborgs.se"]),
.c-accordion a[href$=".pdf"],
.c-accordion a[href$=".doc"],
.c-accordion a[href$=".docx"],
.c-accordion a[href$=".xls"],
.c-accordion a[href$=".xlsx"],
.c-accordion a[href$=".jpg"],
.c-accordion a[href$=".jpeg"],
.c-accordion a[href$=".png"],
.c-accordion a[href$=".gif"],
.c-accordion a[href$=".txt"],
.c-accordion a[href$=".zip"],
.c-accordion a[href*="//"]:not([href*="wihlborgs.se"]) {
	position:relative;
	padding-left:24px;
	line-height:1;
}
.c-text__block a[href$=".pdf"]::before,
.c-text__block a[href$=".doc"]::before,
.c-text__block a[href$=".docx"]::before,
.c-text__block a[href$=".xls"]::before,
.c-text__block a[href$=".xlsx"]::before,
.c-text__block a[href$=".jpg"]::before,
.c-text__block a[href$=".jpeg"]::before,
.c-text__block a[href$=".png"]::before,
.c-text__block a[href$=".gif"]::before,
.c-text__block a[href$=".txt"]::before,
.c-text__block a[href$=".zip"]::before,
.c-text__block a[href*="//"]:not([href*="wihlborgs.se"])::before,
.c-excerpt a[href$=".pdf"]::before,
.c-excerpt a[href$=".doc"]::before,
.c-excerpt a[href$=".docx"]::before,
.c-excerpt a[href$=".xls"]::before,
.c-excerpt a[href$=".xlsx"]::before,
.c-excerpt a[href$=".jpg"]::before,
.c-excerpt a[href$=".jpeg"]::before,
.c-excerpt a[href$=".png"]::before,
.c-excerpt a[href$=".gif"]::before,
.c-excerpt a[href$=".txt"]::before,
.c-excerpt a[href$=".zip"]::before,
.c-excerpt a[href*="//"]:not([href*="wihlborgs.se"])::before,
.c-article a[href$=".pdf"]::before,
.c-article a[href$=".doc"]::before,
.c-article a[href$=".docx"]::before,
.c-article a[href$=".xls"]::before,
.c-article a[href$=".xlsx"]::before,
.c-article a[href$=".jpg"]::before,
.c-article a[href$=".jpeg"]::before,
.c-article a[href$=".png"]::before,
.c-article a[href$=".gif"]::before,
.c-article a[href$=".txt"]::before,
.c-article a[href$=".zip"]::before,
.c-article a[href*="//"]:not([href*="wihlborgs.se"])::before,
.c-accordion a[href$=".pdf"]::before,
.c-accordion a[href$=".doc"]::before,
.c-accordion a[href$=".docx"]::before,
.c-accordion a[href$=".xls"]::before,
.c-accordion a[href$=".xlsx"]::before,
.c-accordion a[href$=".jpg"]::before,
.c-accordion a[href$=".jpeg"]::before,
.c-accordion a[href$=".png"]::before,
.c-accordion a[href$=".gif"]::before,
.c-accordion a[href$=".txt"]::before,
.c-accordion a[href$=".zip"]::before,
.c-accordion a[href*="//"]:not([href*="wihlborgs.se"])::before {
	display:block;
}
