/* =============================================================================
 * Truncate
 *
 * postcss-bem-linter: define truncate
============================================================================= */
[class^="t-truncate--"] {
	display:-webkit-box;
	overflow:hidden;
	-webkit-box-orient:vertical;
}
[class^="t-truncate--"] * {
	margin:0 !important;
}
.t-truncate--1 {
	-webkit-line-clamp:1;
}
.t-truncate--2 {
	-webkit-line-clamp:2;
}
.t-truncate--3 {
	-webkit-line-clamp:3;
}
.t-truncate--4 {
	-webkit-line-clamp:4;
}
.t-truncate--5 {
	-webkit-line-clamp:5;
}
