/* =============================================================================
 * Moodboard
 *
 * postcss-bem-linter: define moodboard
============================================================================= */
.c-moodboard__row {
	@media (--sm) {
		display:flex;
		justify-content:space-between;
	}
}
.c-moodboard__row:not(:last-child) {
	@media (--sm) {
		margin-bottom:10px;
	}

	@media (--sm) {
		margin-bottom:20px;
	}

	@media (--lg) {
		margin-bottom:40px;
	}
}
.c-moodboard__image {
	margin-bottom:10px;
}
.c-moodboard__image:not(:last-child) {
	@media (--sm) {
		margin-right:20px;
	}

	@media (--lg) {
		margin-right:40px;
	}
}
.c-moodboard__image_text {
	width:33%;

	@media (--sm-max) {
		width:100%;
		height:350px;
	}
}


