/* =============================================================================
 * HeadlineText
 *
 * postcss-bem-linter: define headlinetext
============================================================================= */
.c-headlinetext {
	display:flex;
	justify-content:center;
	align-items:center;
	width:100%;
	height:100%;
	background:var(--block-bg);
	color:var(--text);
	font-size:var(--type-sz-20);
	line-height:var(--type-lh-20);
	text-align:center;
}
.c-headlinetext.variant--white {
	font-size:var(--type-sz-50);
}
.c-headlinetext.variant--white h1 {
	color:var(--calypso);
	font-size:125px;
	line-height:80px;
}
.c-headlinetext h1 {
	position:relative;
}
.c-headlinetext.variant--white h1::after {
	height:0;
}
.c-headlinetext h1::after {
	position:absolute;
	bottom:-12px;
	left:calc(50% - 10%);
	width:20%;
	height:2px;
	background:var(--white);
	content:"";
}
