/* =============================================================================
 * Divider
 *
 * postcss-bem-linter: define divider
============================================================================= */
.c-divider {
	background:var(--block-bg);
}
.c-divider__line {
	display:block;
	width:70px;
	height:1px;
	background-color:var(--gray-3);
}
