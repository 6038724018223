/* =============================================================================
 * Divider
 *
 * postcss-bem-linter: define divider
============================================================================= */
.t-divider {
	position:relative;
}
.t-divider::after {
	position:absolute;
	bottom:0;
	left:15px;
	width:70px;
	height:1px;
	background-color:var(--hippie);
	content:"";
}
