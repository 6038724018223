/* =============================================================================
 * Cta-block
 *
 * postcss-bem-linter: define cta-block
============================================================================= */
.c-cta-block {
	background:var(--block-bg);
	color:var(--text);
	font-size:var(--type-sz-20);
	line-height:var(--type-lh-20);
}
.c-cta-block__inner {
	display:flex;
	flex-wrap:wrap;
	align-items:center;
}
.c-cta-block--image {
	padding:0;
	text-align:left;
}
.c-cta-block__text {
	width:100%;

	@media (--md) {
		width:50%;
		padding-right:30px;
	}
}
.c-cta-block img,
.c-cta-block picture {
	width:100%;
	height:100%;
	margin-bottom:20px;

	@media (--md) {
		order:1;
		width:50%;
	}
}
.c-cta-block picture img {
	width:100%;
	height:auto;
}
.c-cta-block__title {
	margin-bottom:10px;
}

/* Image background */
.c-cta-block--image-background {
	position:relative;
}
.c-cta-block__background {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	background-position:50%;
	background-size:cover;
	background-repeat:no-repeat;
	opacity:0.25;
}
.c-cta-block--image-background .c-cta-block__inner {
	position:relative;
	z-index:2;
}

/* Blueprint */
.c-cta-block--blueprint {
	position:relative;

	@media (--lg) {
		background-image:url("/gui/images/blueprint.png");
		background-position:right 0;
		background-size:contain;
		background-repeat:no-repeat;
	}
}
.c-cta-block--blueprint .c-cta-block__text {
	width:100%;

	@media (--lg) {
		width:50%;
	}
}
