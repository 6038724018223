/* =============================================================================
 * Filter dropdown
 * postcss-bem-linter: define filter dropdown
============================================================================= */
.c-filter-dropdown {
	position:relative;
	flex-grow:100;
	min-width:116px;
	max-width:290px;
	width:25%;
}
.c-filter-dropdown__button {
	display:flex;
	position:relative;
	justify-content:space-between;
	align-items:center;
	width:100%;
	padding:10px 20px;
	border:1px solid #e3ddd7;
	border-radius:4px 4px 0 0;
	background-color:var(--white);
	color:#666;
	cursor:pointer;
	min-height: 40px;
}
.c-filter-dropdown__button:hover {
	background-color:var(--gray-5);
}
.c-filter-dropdown__button--active {
	border-color:var(--hippie);
	background-color:#eef3f7;

	@media (--sm) {
		border-bottom:none;
	}
}
.c-filter-dropdown__button-text {
	margin-right:5px;
}
.c-filter-dropdown__button--border-bottom-none {
	border-bottom:none;
}
.c-filter-dropdown__button-tag {
	position:absolute;
	top:-16px;
	left:10px;
	padding:2px 8px;
	border-radius:5px;
	background-color:var(--white);
	font-size:12px;
}
.c-filter-dropdown__toggle-icon {
	fill:#666;
}
.c-filter-dropdown__list {
	display:none;
	position:absolute;
	z-index:10;
	max-height:80vh;
	width:100%;
	overflow-y:auto;
	border:1px solid var(--hippie);
	border-top:none;
	border-radius:0 0 4px 4px;
	list-style:none;
	background-color:var(--white);

	@media (--sm) {
		display:block;
		padding:0 20px;
	}
}

.c-mobile-dropdown__list {
	display:none;
	position:absolute;
	z-index:10;
	max-height:80vh;
	width:100%;
	overflow-y:auto;
	border:1px solid var(--hippie);
	border-top:none;
	border-radius:0 0 4px 4px;
	list-style:none;
	background-color:var(--white);

	@media (--sm) {
		display:block;
		padding:0 20px;
	}
}

.c-filter-dropdown__city {
	position:relative;
	margin-bottom:24px;
	padding:16px 0;
}
.c-filter-dropdown__city::after {
	position:absolute;
	bottom:0;
	left:0;
	width:44px;
	height:1px;
	background:var(--black);
	content:"";
}
.c-filter-dropdown__region {
	padding:12px;
}
.c-filter-dropdown__region input,
.c-filter-dropdown__city input {
	margin-right:16px;
	border:1px solid #2f688a;
}