/* =============================================================================
 * Visually hidden
 *
 * Hidden visually, but screen readers will read it.
 * postcss-bem-linter: define visually-hidden
============================================================================= */
.t-visually-hidden {
	position:absolute;
	width:1px;
	height:1px;
	overflow:hidden;
	clip:rect(1px, 1px, 1px, 1px);
	padding:0;
	border:0;
	white-space:nowrap;
}
.t-visually-hidden--focusable:active,
.t-visually-hidden--focusable:focus {
	position:static;
	width:auto;
	height:auto;
	overflow:visible;
	clip:auto;
	margin:0;
	white-space:inherit;
}
