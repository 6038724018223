/* =============================================================================
 * Faq
 *
 * postcss-bem-linter: define faq
============================================================================= */
.c-faq {
	background-color:var(--block-bg);
	color:var(--text);
}
.c-faq__items {
	margin:0;
	margin-top:48px;
	padding:0;
	list-style:none;
}
.c-faq__item:not(:last-of-type) {
	border-bottom:1px solid var(--hippie-25);
}
