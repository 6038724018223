/* =============================================================================
 * Conveniences
 *
 * postcss-bem-linter: define conveniences
============================================================================= */
.c-conveniences {
	background-color:var(--block-bg);
	color:var(--text);
}
.c-conveniences__list {
	display:grid;
	grid-template-columns:100%;
	row-gap:12px;
	list-style:none;
	column-gap:12px;

	@media (--sm) {
		grid-template-columns:33% 33% 33%;
		row-gap:24px;
		column-gap:24px;
	}
}
.c-conveniences__item {
	display:flex;
	align-items:center;
}
.c-conveniences__label {
	margin-left:24px;
}
.c-conveniences__icon[class] {
	fill:var(--text);
}
