/* =============================================================================
 * Chip
 *
 * 1. Due to theming
 *
 * postcss-bem-linter: define chip
============================================================================= */
.c-chip {
	display:inline-flex;
	justify-content:center;
	align-items:center;
	height:32px;
	padding:1px 14px 0 14px;
	border:0;
	border-radius:100px;
	background-color:var(--control-bg-inverted);
	color:var(--control-text-inverted);
	font-size:var(--type-sz-10);
	line-height:var(--type-lh-10);
	text-decoration:none;
}
.c-chip {
	font-weight:500;
}
.c-chip--small {
	height:25px;
	font-size:var(--type-sz-5);
	line-height:var(--type-lh-5);
}
.c-chip:hover,
.c-chip:focus {
	color:var(--control-text-inverted);
	opacity:0.9;
}
.c-chip__icon[class] {
	width:14px;
	height:14px;
	margin-right:1px;
	margin-bottom:1px;
	fill:var(--control-text-inverted);
}
.c-chip--inverted[class] {
	background-color:var(--control-bg);
	color:var(--control-text);
}
.c-chip--inverted[class]:hover,
.c-chip--inverted[class]:focus {
	color:var(--control-text);
	opacity:0.9;
}
.c-chip--inverted[class] .c-chip__icon {
	fill:var(--control-text);
}
.c-chip--circle {
	width:32px;
	padding:0;
}
.c-chip--ghost {
	border:1px solid var(--contrast);
	background-color:var(--white);
}
.c-chip--ghost:hover {
	border-color:var(--control-bg);
	background-color:var(--control-bg);
	color:var(--control-text);
	opacity:1;
}
