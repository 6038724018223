.h2 {
  font-size: var(--type-sz-80);
  line-height: var(--type-lh-80);
  font-family: var(--type-ff-header);
  font-weight: bolder;
}

.offices-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-between;
  padding-top: 20px;
  background-color: white;
  margin-top: 30px;
  margin-bottom: 30px;
}

.offices-item {
  flex-basis: 50%;
  box-sizing: border-box;
  padding: 1px;
  margin: 1px;
  min-width: 320px;
}

.text-row {
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.4;
}

.text-row:last-child {
  margin-bottom: 0;
}