.c-contact-filter {
  background-color: white;
}
.c-contact-filter__wrapper {
  background: var(--pearl-50);
  height: 120px;
  padding-top: 10px;
}

@media (max-width: 767px) {
  .c-contact-filter__wrapper {
    background: var(--pearl-15);
  }
}

.c-mobile-filter__wrapper {
  background: var(--pearl-15);
  height: 120px;
  padding-top: 10px;
}

.c-contact-filter__content-wrapper {
  background: var(--pearl-50);
}

@media (max-width: 767px) {
.c-contact-filter__content-wrapper {
  background: var(--pearl-15);
}
}

.c-mobile-filter__content-wrapper {
  background: var(--pearl-15);
}

.c-contact-filter__content-wrapper .c-filter-dropdown {
  max-width: none;
  flex: 1;
  margin-top: 5px;
  padding-top: 3px;
}

@media (max-width: 767px) {
  .c-contact-filter__content-wrapper {
    background: var(--pearl-15);
  }
}

.c-mobile-filter__wrapper {
  background: var(--pearl-15);
  height: 120px;
  padding-top: 10px;
}

.c-mobile-filter__content-wrapper {
  background: var(--pearl-15);
}

.c-mobile-filter__content-wrapper .c-filter-dropdown {
  max-width: none;
  flex: 1;
  margin-top: 5px;
  padding-top: 3px;
}

.c-contact-filter__dropdown-wrapper {
  background: var(--pearl-50);
}

@media (max-width: 767px) {
  .c-contact-filter__dropdown-wrapper {
    background: var(--pearl-15);
  }
}


.c-mobile-filter__dropdown-wrapper {
  background: var(--pearl-15);
}
.c-contact-filter__search {
  display: flex;
  align-items: center;
  padding: 0 !important;
  flex: 1;
  border-radius: 30px;
  height: 44px;
  margin-top: 8px;
}

.c-mobile-contactsearch {
  padding: 0 !important;
  flex: 1;
  border-radius: 30px;
  height: 40px;
}

.c-mobile-contactsearch input {
  border: none !important;
  background-color: white;
}

.c-contact-filter__search input {
  border: none !important;
}
.c-contact-filter__search input::-webkit-input-placeholder {
  color: #666666;
}
.c-contact-filter__search input:-ms-input-placeholder {
  color: #666666;
}
.c-contact-filter__search input::-ms-input-placeholder {
  color: #666666;
}
.c-contact-filter__search input::placeholder {
  color: #666666;
}
.c-contact-filter__button {
  border: none !important;
  cursor: pointer;
  right: 5px;
  background: none;
  border: none;
}
@media (max-width: 767px) {
  .c-contact-filter__button {
    padding-right: 5px;
  }
}

.clear-button {
  width: 42px !important;
  border: none !important;
  cursor: pointer;
  background: none;
  border: none;
}

.container-search-button {
  display: flex;
  border-left: 1px solid var(--pearl-50);
  width: 16%;
  height: 100%;
}

.container-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-text {
  margin-bottom: 5px;
  padding-right: 15px;
}

.slider-icon {
  margin-bottom: 7px;
}

.c-contact-filter .c-filter__city {
  margin-bottom: 0 !important;
  padding: 10px 0 !important;
}
.c-contact-filter .c-filter__city::after {
  content: none !important;
}
.c-contact-filter .c-filter-dropdown__list {
  max-height: 400px;
}
.c-contact-filter .c-filter-dropdown__list li {
  font-weight: 600;
}
.c-contact-filter .c-filter-dropdown__list .c-filter-department-wrapper li {
  font-weight: 300;
}
.c-contact-filter .c-filter-dropdown__list li p {
  padding-left: 30px !important;
  font-weight: 300;
}
.c-contact-filter .c-filter-dropdown__list li span {
  display: block;
  padding: 10px;
}
.c-contact-filter .c-filter-dropdown__list li span:hover,
.c-contact-filter .c-filter-dropdown__list li p:hover {
  background-color: #ddd;
  cursor: pointer;
}
