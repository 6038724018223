/* =============================================================================
 * Usp
 *
 * postcss-bem-linter: define usp
============================================================================= */
.c-usp {
	background-color:var(--block-bg);
	color:var(--text);
}
.c-usp__items {
	display:flex;
	justify-content:space-between;
	margin:40px 0 -40px 0;
	padding:0 20px;
	flex-flow:wrap row;
}
.c-usp__item {
	display:flex;
	position:relative;
	align-items:flex-start;
	flex-basis:auto;
	margin:0 -20px 40px -20px;
	flex-flow:nowrap row;

	@media(--sm) {
		flex-basis:50%;
	}
}
.c-usp__item p:last-child {
	margin-bottom:0;
}
.c-usp svg {
	flex-shrink:0;
	margin-right:20px;
}
.c-usp__item-title {
	margin:0 0 4px 0;
}
