/* =============================================================================
 * Page-navigation
 *
 * postcss-bem-linter: define page-nav
============================================================================= */
.c-page-nav {
	display:none;
	align-items:center;
	width:100%;
	background-color:var(--block-bg);
	color:var(--text);

	@media (--sm) {
		display:flex;
		align-items:center;
	}
}
.c-page-nav__wrapper {
	top:-100px;
	width:100%;
}
.c-page-nav.is-stuck .c-page-nav__wrapper {
	position:fixed;
	top:0;
	z-index:3;
	box-shadow:rgba(0, 0, 0, 0.1) 4px 0 5px 4px;
	background-color:var(--block-bg);
	transition:top 0.4s ease-in-out;
}
.c-page-nav__inner {
	display:flex;
	width:100%;
}
.c-page-nav__item {
	display:flex;
	position:relative;
	justify-content:center;
	align-items:center;
	flex-grow:1;
}
.c-page-nav__link {
	display:flex;
	justify-content:center;
	align-items:center;
	padding:18px 10px;
	color:inherit;
	font-size:var(--type-sz-10);
	line-height:var(--type-lh-10);
	text-decoration:none;
	flex-flow:column;
}
.c-page-nav.is-stuck .c-page-nav__link {
	padding:12px 10px;
	flex-flow:row;
}
.c-page-nav.is-stuck .c-page-nav__icon {
	margin:0 12px 0 0;
}
.c-page-nav__item:not(:last-child)::after {
	position:absolute;
	right:0;
	width:1px;
	height:40%;
	background-color:var(--gray-3);
	content:"";
}
.c-page-nav__icon {
	margin-bottom:10px;
	fill:var(--gray-2);
}
.c-page-nav__link:hover .c-page-nav__icon {
	fill:currentColor;
}
