/* =============================================================================
 * Zig zag
 *
 * postcss-bem-linter: define zig-zag
============================================================================= */
.c-zig-zag {
	overflow-x:hidden;
}
.c-zig-zag__inner {
	@media (--sm) {
		display:flex;
		align-items:center;
	}
}
.c-zig-zag__media {
	position:relative;
	flex:2;

	@media (--sm-max) {
		left:-20px;
		width:100vw;
		margin-bottom:24px;
	}

	@media (--sm) {
		right:-20px;
		order:2;
		padding-left:70px;
	}

	@media (--xl) {
		right:-160px;
		padding:0;
	}
}

.c-zig-zag__media iframe{

	@media (--sm-max) {
		padding-left:20px;
		padding-right:20px;
	}

	@media (--sm) {
		width:calc(100% - 20px) !important;	
	}

	@media (--xl) {
		width:calc(100% - 160px) !important;
	}
}
.c-zig-zag__inner--left .c-zig-zag__media {
	@media (--sm) {
		left:-20px;
		order:-1;
		padding-right:70px;
		padding-left:0;
	}

	@media (--xl) {
		right:auto;
		left:-160px;
	}
}
.c-zig-zag__media img {
	width:100%;
}
.c-zig-zag__content {
	display:flex;
	position:relative;
	flex-direction:column;
	justify-content:center;
	flex:1;

	@media (--sm) {
		right:-20px;
	}

	@media (--md) {
		max-width:270px;
		padding-right:20px;
	}

	@media (--xl) {
		right:auto;
	}
}
.c-zig-zag__inner--left .c-zig-zag__content {
	@media (--sm) {
		right:auto;
		left:-40px;
	}

	@media (--md) {
		left:auto;
	}
}
.c-zig-zag__title {
	font-size:var(--type-sz-60);
	line-height:var(--type-lh-60);
}
