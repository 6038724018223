/* =============================================================================
 * Margin
 *
 * postcss-bem-linter: define margin
============================================================================= */
.t-margin-top {
	margin-top:3px !important;
}
.t-margin-top-s {
	margin-top:6px !important;
}
.t-margin-top-m {
	margin-top:12px !important;
}
.t-margin-top-l {
	margin-top:24px !important;
}
.t-margin-top-xl {
	margin-top:36px !important;
}
.t-margin-top-xxl {
	margin-top:48px !important;
}
.t-margin-top-xxxl {
	margin-top:72px !important;
}
.t-margin-top-none {
	margin-top:0 !important;
}
.t-margin-bottom {
	margin-bottom:3px !important;
}
.t-margin-bottom-s {
	margin-bottom:6px !important;
}
.t-margin-bottom-m {
	margin-bottom:12px !important;
}
.t-margin-bottom-l {
	margin-bottom:24px !important;
}
.t-margin-bottom-xl {
	margin-bottom:36px !important;
}
.t-margin-bottom-xxl {
	margin-bottom:48px !important;
}
.t-margin-bottom-xxxl {
	margin-bottom:72px !important;
}
.t-margin-bottom-none {
	margin-bottom:0 !important;
}
.t-margin-left {
	margin-left:3px !important;
}
.t-margin-left-s {
	margin-left:6px !important;
}
.t-margin-left-m {
	margin-left:12px !important;
}
.t-margin-left-l {
	margin-left:24px !important;
}
.t-margin-left-xl {
	margin-left:36px !important;
}
.t-margin-left-xxl {
	margin-left:48px !important;
}
.t-margin-left-xxxl {
	margin-left:72px !important;
}
.t-margin-left-none {
	margin-left:0 !important;
}
.t-margin-right {
	margin-right:3px !important;
}
.t-margin-right-s {
	margin-right:6px !important;
}
.t-margin-right-m {
	margin-right:12px !important;
}
.t-margin-right-l {
	margin-right:24px !important;
}
.t-margin-right-xl {
	margin-right:36px !important;
}
.t-margin-right-xxl {
	margin-right:48px !important;
}
.t-margin-right-xxxl {
	margin-right:72px !important;
}
.t-margin-right-none {
	margin-right:0 !important;
}
