/* =============================================================================
 * heroblock
 * postcss-bem-linter: define heroblock
============================================================================= */

.c-heroblock__title {
  margin-bottom: 15px;
  word-break: break-word;
  height: 40%;
}

.c-heroblock__title--small {
  font-size: var(--type-sz-80);
  line-height: var(--type-lh-80);
  font-family: var(--type-ff-header);
}

.c-heroblock {
  position: relative;
  width: 100%;
}

.c-heroblock-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  pointer-events: none;
}

.c-heroblock-media {
  position: relative;
  width: 100%;
  height: 688px;
  overflow: hidden;
}

@media (max-width: 640px) {
  .c-heroblock-media {
    height: 270px;
  }
}

.c-heroblock-video {
  position: relative;

  div {
    video {
      width: 100%;
      height: 688px;
      overflow: hidden;
      object-fit: cover;
      vertical-align: middle;
    }
  }
}

@media (max-width: 640px) {
  .c-heroblock-video {
    height: 270px;

    div {
      video {
        height: 270px;
      }
    }
  }
}

.c-heroblock-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-heroblock-text {
  position: absolute;
  background-color: white;
  padding: 80px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-height: 492px;
  width: 474px;
  margin-right: 10%;
  margin-left: 10%;
  top: 50%;
  transform: translateY(-50%);
}

.c-heroblock-text.c-hidebox {
  margin-top: 0;
  padding-top: 0;
}

@media (max-width: 640px) {
  .c-heroblock-text.c-hidebox {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 640px) {
  .c-heroblock-text {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 210px;
    padding: 32px 20px;
    margin-right: 0;
    margin-left: 0;
    top: 0;
    transform: none;
  }
}

.c-heroblock-text h2 {
  margin-bottom: 0;
  padding-bottom: 24px;
  font-size: 56px;
  font-family: var(--type-ff-header);
  color: #86321D;
  width: 100%;
  line-height: 64px;
  letter-spacing: 1;
}

@media (max-width: 640px) {
  .c-heroblock-text h2 {
    width: 100%;
    line-height: 1.214em;
    margin-top: 0;
    font-size: 28px;
    font-weight: 700;
  }
}

.c-heroblock-text p {
  margin-bottom: 0;
  padding-bottom: 40px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  width: 100%;
}

@media (max-width: 640px) {
  .c-heroblock-text p {
    display: none;
  }
}

.c-heroblock-text__button {
  display: block;
  background-color: transparent;
  color: #333333;
  border: 1px solid #333333;
  padding: 15px 40px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 18px;
  font-family: var(--type-ff-default);
  font-weight: 500;
  line-height: 26px;
  height: 56px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 288px;
  text-decoration: none;
  margin: auto;
}

.c-heroblock-text__button:hover {
  transform: scale(1.02);
  transition: transform 0.3s;
  text-decoration: underline;
  background-color: rgba(255, 255, 255, 0.3);
}

.c-heroblock-text__button:after {
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20448%20512%22%3E%3Cpath%20d%3D%22M435.3%20267.3L446.6%20256l-11.3-11.3-168-168L256%2065.4%20233.4%2088l11.3%2011.3L385.4%20240%2016%20240%200%20240l0%2032%2016%200%20369.4%200L244.7%20412.7%20233.4%20424%20256%20446.6l11.3-11.3%20168-168z%22/%3E%3C/svg%3E");
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
  width: 18px;
  height: 21px;
  margin-left: 16px;
}

.inverted-arrow:after {
  filter: invert(1);
}

@media (max-width: 640px) {
  .c-heroblock-text__button {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    padding: 8px 24px;
    height: 40px;
  }
}

.c-heroblock-text button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.c-hidebox {
  background-color: transparent;
  width: 450px;
  max-height: 60%;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;

  h2 {
    color: #fff;
  }

  p {
    color: #fff;
  }

  .c-heroblock-text__button {
    color: #fff;
    border: 1px solid #fff
  }

  .c-heroblock-text__button:after {
    filter: invert(1);
  }
}

@media (max-width: 640px) {
  .c-hidebox {
    padding-top: 24px;
    width: 100%;
  }
}

.left {
  left: 0;
  margin-left: 10%;
}

@media (max-width: 640px) {
  .left {
    margin-left: 0;
  }
}

@media (min-width: 641px) and (max-width: 1024px) {
  .left {
    left: 40%;
    transform: translate(-50%, -50%);
  }
}

.center {
  left: 40%;
  transform: translate(-50%, -50%);
}

@media (max-width: 640px) {
  .center {
    left: 0;
    transform: none;
  }
}

.right {
  right: 0;
  margin-right: 365px;
}

@media (max-width: 640px) {
  .right {
    margin-right: 0;
  }
}

@media (min-width: 641px) and (max-width: 1024px) {
  .right {
    left: 40%;
    transform: translate(-50%, -50%);
  }
}