/* =============================================================================
 * Range slider
 *
 * postcss-bem-linter: define range-slider
============================================================================= */
.c-range-slider {
	position:relative;
	padding-top:50px;

	@media (--xs) {
		padding-top:30px;
	}
}
.c-range-slider__header {
	display:flex;
	justify-content:space-between;
	align-items:center;
	margin-bottom:20px;
}
.c-range-slider__values {
	display:flex;
	position:absolute;
	top:4px;
	right:0;
	flex-wrap:wrap;
	align-items:flex-end;
	height:40px;
}
.c-range-slider__label {
	width:100%;
	font-size:var(--type-sz-5);
	line-height:var(--type-lh-5);
}
.c-range-slider__label {
	font-weight:bold;
}
.c-range-slider__values:first-of-type {
	right:auto;
	left:0;
}
.c-range-slider__control {
	position:relative;
	max-width:90%;
	margin:0 auto 10px auto;

	@media (--xs) {
		max-width:62%;
	}

	@media (--lg) {
		max-width:70%;
	}
}
.c-range-slider__control--nojs {
	display:flex;
	justify-content:space-between;
	align-items:center;
	max-width:100%;
	margin:18px 0 0;
}
.c-range-slider__input {
	max-width:150px;
}
.c-range-slider__track {
	height:8px;
	border-radius:6px;
	background-color:var(--hippie);
}
.c-range-slider__fill {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:8px;
	background-color:var(--hippie);
}
.c-range-slider__thumb-container {
	position:absolute;
	top:-10px;
}
.c-range-slider__thumb-container:focus .c-range-slider__thumb {
	outline:none;
	border:3px solid var(--white);
	box-shadow:0 0 0 2px var(--calypso);
	background-color:var(--white);
}
.c-range-slider__thumb {
	position:absolute;
	top:50%;
	left:-16px;
	width:26px;
	height:26px;
	border:1px solid var(--gray-3);
	border-radius:16px;
	box-shadow:0 4px 10px 0 rgba(0, 0, 0, 0.07);
	background-color:var(--white);
	touch-action:none;
}
.c-range-slider__thumb:hover,
.c-range-slider__thumb:focus {
	background-color:var(--gray-4);
	cursor:pointer;
}
