/* =============================================================================
 * Splash
 *
 * postcss-bem-linter: define splash
============================================================================= */
.c-splash {
	background-color:var(--white);
	color:var(--gray-1);
}
.c-splash__background-image {
	width:100%;
	height:240px;
	object-fit:cover;

	@media (--md) {
		height:500px;
	}
}
.c-splash__inner {
	display:flex;
	position:relative;
	justify-content:center;
	flex-wrap:wrap;
}
.c-splash__content {
	width:100%;
	width:80%;
	padding-bottom:20px;
	text-align:center;

	@media (--md) {
		padding-bottom:40px;
	}
}
.c-splash__content::before {
	position:absolute;
	top:-38px;
	left:5%;
	width:90%;
	height:40px;
	background-color:var(--white);
	content:"";

	@media (--md) {
		top:-60px;
		height:60px;
	}

	@media (--lg) {
		left:0;
		width:100%;
	}
}
.c-splash__title {
	margin-top:2px;
	margin-bottom:20px;

	@media (--md) {
		font-size:var(--type-sz-140);
		line-height:var(--type-lh-140);
	}
}
.c-splash__text p {
	margin-bottom:10px;
	font-size:var(--type-sz-20);
	line-height:var(--type-lh-20);

	@media (--md) {
		margin-bottom:15px;
	}
}
.c-splash__button[class] {
	margin-top:24px;
	background-color:var(--hero-control-bg);
	color:var(--white);
}
