/* =============================================================================
 * Grid block
 *
 * postcss-bem-linter: define grid-block
============================================================================= */
.c-grid-block.t-padding--v.o-width-limiter--narrow {
	--space: 44px;

	@media (--md) {
		--space: 64px;
	}

	.c-grid-block__item {
		transform: none;
		transition: none;
	}
}

.slide-in {
	opacity: 0;
	transform: translateX(-10px);
	transition: opacity 3s, transform 3s;
	animation: fadeInLeft 4s ease-in-out forwards;
}

@media (prefers-reduced-motion) {
	.slide-in {
		transform: translateX(0);
		transition: transform 0s;
	}
}

.slide-in.visible {
	opacity: 1;
	transform: translateX(0);
}

html {
	scroll-behavior: smooth;
}

.c-grid-block.column-1 {
	display: grid;
	grid-template-rows: 480px;
	grid-auto-rows: 480px;
	grid-template-columns: repeat(1, minmax(200px, 1fr));
	gap: 30px;
	padding-top: 15px !important;
	padding-bottom: 15px !important; 

	&.c-grid-block__iframe {
		grid-template-rows: auto;
	}

	.c-grid-block__item {
		padding: 0;

		video {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: translate(-50%, -50%);
		}

		.c-grid-block__content.c-grid-block__content-center {
			left: 50%;
			transform: translateX(-50%);
		}

		.c-grid-block__content.c-grid-block__content-right {
			margin-right: 110px;
			left: 80%;
			transform: translateX(-50%);
		}

		.c-grid-block__content {
			position: relative;
			width: 80%;
			height: 80%;
			max-height: 300px;
			max-width: 300px;
			left: 10%;
			right: 10%;

			.white-underline {
				background-color: white;
			}

			.black-underline {
				background-color: black;
			}

			.underline {
				width: 64px;
				height: 2px;
				display: inline-block;
				margin: 0 auto 16px auto;
			}

			.c-grid-block__title {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				line-height: var(--type-lh-60);
				font-family: var(--type-ff-header);
				height: auto;
				width: 80%;
				max-width: 236px;
				text-align: center;
				margin-bottom: 16px;
			}

			.c-grid-block__title::after {
				width: 50px;
				height: 2px;
				background-color: rgba(205, 205, 205, 1);
				margin: 10px auto;
			}

			p {
				text-align: center;
				padding: 1px 0 4px 0;
			}

			a {
				background-color: transparent;
				color: #333333;
				border: 1px solid #333333;
				padding: 10px 20px;
				border-radius: 20px;
				cursor: pointer;
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				height: 44px;
				text-decoration: none;
				align-items: center;
			}

			a:after {
				content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20448%20512%22%3E%3Cpath%20d%3D%22M435.3%20267.3L446.6%20256l-11.3-11.3-168-168L256%2065.4%20233.4%2088l11.3%2011.3L385.4%20240%2016%20240%200%20240l0%2032%2016%200%20369.4%200L244.7%20412.7%20233.4%20424%20256%20446.6l11.3-11.3%20168-168z%22/%3E%3C/svg%3E");
				display: inline-block;
				margin-left: 8px;
				vertical-align: middle;
				width: 18px;
				height: 21px;
				margin-left: 16px;
			}

			a:hover {
				transform: scale(1.02);
				transition: transform 0.3s;
				text-decoration: underline;
				background-color: rgba(255, 255, 255, 0.3);
			}

			.inverted-arrow:after {
				filter: invert(1);
			}
		}
	}
}

@media (max-width: 767px) {
	.c-grid-block.column-1 {
		display: block;

		.c-grid-block__item.c-grid-block__item-iframe {
			height: 100%;
			min-height: 218px;
			padding: 0;
		}

		.c-grid-block__item {
			padding: 10% 1px;

			.c-grid-block__content.c-grid-block__content-right {
				left: 40%;
				margin: auto;
			}

			.c-grid-block__content-right {
				left: 40%;
				margin: auto;
			}

			.c-grid-block__content {
				padding: 20px 0px;
			}
		}
	}
}

.c-grid-block.column-2 {
	display: grid;
	grid-template-rows: 380px;
	grid-auto-rows: 380px;
	grid-template-columns: repeat(2, minmax(420px, 1fr));
	gap: 30px;
	padding-top: 15px !important;
	padding-bottom: 15px !important;

	.c-grid-block__item {
		padding: 0;

		video {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: translate(-50%, -50%);
		}

		.c-grid-block__content {
			position: relative;
			width: 80%;
			height: 80%;
			max-height: 316px;
			max-width: 420px;
			left: 10%;
			right: 10%;

			.white-underline {
				background-color: white;
			}

			.black-underline {
				background-color: black;
			}

			.underline {
				width: 64px;
				height: 2px;
				display: inline-block;
				margin: 0 auto 16px auto;
			}

			.c-grid-block__title {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				line-height: var(--type-lh-60);
				font-family: var(--type-ff-header);
				height: auto;
				width: 80%;
				max-width: 330px;
				text-align: center;
				margin-bottom: 16px;
			}

			.c-grid-block__title::after {
				width: 50px;
				height: 2px;
				background-color: rgba(205, 205, 205, 1);
				margin: 10px auto;
			}

			p {
				text-align: center;
				padding: 1px 32px 4px 32px;
			}

			a {
				background-color: transparent;
				color: #333333;
				border: 1px solid #333333;
				padding: 10px 20px;
				border-radius: 20px;
				cursor: pointer;
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				height: 44px;
				text-decoration: none;
				align-items: center;
			}

			a:hover {
				transform: scale(1.02);
				transition: transform 0.3s;
				text-decoration: underline;
				background-color: rgba(255, 255, 255, 0.3);
			}

			a:after {
				content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20448%20512%22%3E%3Cpath%20d%3D%22M435.3%20267.3L446.6%20256l-11.3-11.3-168-168L256%2065.4%20233.4%2088l11.3%2011.3L385.4%20240%2016%20240%200%20240l0%2032%2016%200%20369.4%200L244.7%20412.7%20233.4%20424%20256%20446.6l11.3-11.3%20168-168z%22/%3E%3C/svg%3E");
				display: inline-block;
				margin-left: 8px;
				vertical-align: middle;
				width: 18px;
				height: 21px;
				margin-left: 16px;
			}

			.inverted-arrow:after {
				filter: invert(1);
			}
		}
	}
}

.c-grid-block__item--twothirds {
	grid-column: span 2;
	width: calc(100% + 35%);

	div {
		position: initial !important;
	}

	.c-grid-block__content {
		left: 20%;
	}
}

.c-grid-block__item--onethirds {
	width: 65%;
	left: 35%;
}

@media (max-width: 767px) {
	.c-grid-block__item--onethirds {
		width: 100%;
		left: 0;
	}
}

@media (max-width: 767px) {
	.c-grid-block__item--twothirds {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.c-grid-block.column-2 {
		display: block;

		.c-grid-block__item.c-grid-block__item-iframe {
			height: 100%;
			min-height: 218px;
			padding: 0;
		}

		.c-grid-block__item {
			padding: 10% 1px;
			margin-bottom: 30px;

			.c-grid-block__content {
				padding: 20px 0px;
			}
		}

		.c-grid-block__item:last-child {
			margin-bottom: 0;
		}
	}
}

@media (max-width: 920px) {
	.c-grid-block.column-2 {
		grid-template-columns: repeat(2, minmax(380px, 1fr));
	}
}

.c-grid-block.column-3 {
	display: grid;
	grid-template-rows: 380px;
	grid-auto-rows: 380px;
	grid-template-columns: repeat(3, minmax(275px, 1fr));
	gap: 30px;
	padding-top: 15px !important;
	padding-bottom: 15px !important;

	.c-grid-block__item {
		padding: 0;

		video {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: translate(-50%, -50%);
		}

		.c-grid-block__content {
			position: relative;
			width: 80%;
			height: 80%;
			max-height: 316px;
			left: 10%;
			right: 10%;

			.white-underline {
				background-color: white;
			}

			.black-underline {
				background-color: black;
			}

			.underline {
				width: 64px;
				height: 2px;
				display: inline-block;
				margin: 0 auto 16px auto;
			}

			.c-grid-block__title {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				line-height: var(--type-lh-60);
				font-family: var(--type-ff-header);
				height: auto;
				width: 80%;
				max-width: 330px;
				text-align: center;
				margin-bottom: 16px;
			}

			.c-grid-block__title::after {
				width: 50px;
				height: 2px;
				background-color: rgba(205, 205, 205, 1);
				margin: 10px auto;
			}

			p {
				text-align: center;
				padding: 1px 32px 4px 32px;
			}

			a {
				background-color: transparent;
				color: #333333;
				border: 1px solid #333333;
				padding: 10px 20px;
				border-radius: 20px;
				cursor: pointer;
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				height: 44px;
				text-decoration: none;
				align-items: center;
			}

			a:hover {
				transform: scale(1.02);
				transition: transform 0.3s;
				text-decoration: underline;
				background-color: rgba(255, 255, 255, 0.3);
			}

			a:after {
				content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20448%20512%22%3E%3Cpath%20d%3D%22M435.3%20267.3L446.6%20256l-11.3-11.3-168-168L256%2065.4%20233.4%2088l11.3%2011.3L385.4%20240%2016%20240%200%20240l0%2032%2016%200%20369.4%200L244.7%20412.7%20233.4%20424%20256%20446.6l11.3-11.3%20168-168z%22/%3E%3C/svg%3E");
				display: inline-block;
				margin-left: 8px;
				vertical-align: middle;
				width: 18px;
				height: 21px;
				margin-left: 16px;
			}

			.inverted-arrow:after {
				filter: invert(1);
			}
		}
	}
}

@media (max-width: 767px) {
	.c-grid-block.column-3 {
		display: block;

		.c-grid-block__item {
			padding: 10% 1px;
			margin-bottom: 30px;

			.c-grid-block__content {
				padding: 20px 0px;
			}
		}
	}
}

@media (max-width: 920px) {
	.c-grid-block.column-3 {
		grid-template-columns: repeat(3, minmax(240px, 1fr));
	}
}

.c-grid-block.column-4 {
	display: grid;
	grid-template-rows: 326px;
	grid-auto-rows: 326px;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 30px;
	padding-top: 15px !important;
	padding-bottom: 15px !important;

	.c-grid-block__item {
		padding: 0;

		video {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: translate(-50%, -50%);
		}

		.c-grid-block__content {
			position: relative;
			width: 80%;
			height: 80%;
			max-height: 300px;
			left: 10%;
			right: 10%;

			.white-underline {
				background-color: white;
			}

			.black-underline {
				background-color: black;
			}

			.underline {
				width: 64px;
				height: 2px;
				display: inline-block;
				margin: 0 auto 16px auto;
			}

			.c-grid-block__title {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				line-height: var(--type-lh-60);
				font-family: var(--type-ff-header);
				height: auto;
				width: 100%;
				max-width: 236px;
				text-align: center;
				margin-bottom: 16px;
			}

			.c-grid-block__title::after {
				width: 50px;
				height: 2px;
				background-color: rgba(205, 205, 205, 1);
				margin: 10px auto;
			}

			p {
				text-align: center;
				padding: 1px 0px 4px 0px;
			}

			a {
				background-color: transparent;
				color: #333333;
				border: 1px solid #333333;
				padding: 10px 20px;
				border-radius: 20px;
				cursor: pointer;
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				height: 44px;
				text-decoration: none;
				align-items: center;
			}

			a:hover {
				transform: scale(1.02);
				transition: transform 0.3s;
				text-decoration: underline;
				background-color: rgba(255, 255, 255, 0.3);
			}

			a:after {
				content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20448%20512%22%3E%3Cpath%20d%3D%22M435.3%20267.3L446.6%20256l-11.3-11.3-168-168L256%2065.4%20233.4%2088l11.3%2011.3L385.4%20240%2016%20240%200%20240l0%2032%2016%200%20369.4%200L244.7%20412.7%20233.4%20424%20256%20446.6l11.3-11.3%20168-168z%22/%3E%3C/svg%3E");
				display: inline-block;
				margin-left: 8px;
				vertical-align: middle;
				width: 18px;
				height: 21px;
				margin-left: 16px;
			}

			.inverted-arrow:after {
				filter: invert(1);
			}
		}
	}
}

@media (max-width: 767px) {
	.c-grid-block.column-4 {
		display: block;

		.c-grid-block__item {
			padding: 10% 1px;
			margin-bottom: 30px;

			.c-grid-block__content {
				padding: 20px 0px;
			}
		}
	}
}

@media (max-width: 920px) {
	.c-grid-block.column-4 {
		grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
	}
}

.gridblock {
	.c-grid-block {
		.c-grid-block__item {
			margin-bottom: 30px;

			.c-grid-block__content {
				display: flex;
				z-index: 1;
				justify-content: center;
				align-items: baseline;
				max-width: 560px;
				height: 100%;
				padding: calc(var(--space) / 2) var(--space);
				background-color: inherit;
				flex-flow: column;

				h2 {
					justify-content: left;
				}

				p {
					width: 80%;
				}
			}
		}
	}
}

.c-grid-block__item {
	display: flex;
	position: relative;
	justify-content: center;
	grid-column: 1 / span 2;
	min-height: 240px;
	padding: calc(var(--space) / 2) calc(var(--space) / 2) calc(var(--space) / 2) 0;
	background-color: var(--pearl-50);
	flex-flow: column;

	@media (--sm) {
		grid-column: unset;
		min-height: 320px;
		padding: calc(36px / 2) 0;
		padding: calc(var(--space) / 2) 0;
	}
}

.c-grid-block__item p:last-child {
	margin-bottom: 0;
}

.c-grid-block__item--full {
	grid-column: 1 / span 2;
}

.c-grid-block__item--align-right {
	padding: calc(var(--space) / 2) 0 calc(var(--space) / 2) calc(var(--space) / 2);

	@media (--sm) {
		padding: calc(var(--space) / 2) 0 calc(var(--space) / 2) 0;
	}
}

.c-grid-block__item--align-right .c-grid-block__content[class] {
	align-self: flex-end;
	margin: 0;
}

.c-grid-block__content {
	display: flex;
	z-index: 1;
	justify-content: center;
	align-items: center;
	height: 326px;
	max-width: 300px;
	padding: calc(var(--space) / 2) var(--space);
	background-color: inherit;
	flex-flow: column;
}

.c-grid-block__media {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.c-grid-block__media~.c-grid-block__content {
	@media (--xs) {
		width: 85%;
	}

	@media (--sm) {
		margin-right: var(--space);
	}
}

.c-grid-block__item--full .c-grid-block__content {
	max-width: none;

	@media (--sm) {
		width: calc(50% - calc(var(--space) / 4));
	}
}

.c-grid-block__image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.c-grid-block__title {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--type-sz-60);
	line-height: var(--type-lh-60);
	font-family: var(--type-ff-header);
	height: 84px;
	width: 236px;
	text-align: center;
}

.c-grid-block__title h2 {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--type-sz-60);
	line-height: var(--type-lh-60);
	font-family: var(--type-ff-header);
	height: 84px;
	width: 236px;
	text-align: center;
}

.c-grid-block__line {
	width: 50px;
	height: 2px;
	background-color: rgba(205, 205, 205, 1);
	margin: 10px auto;
}

.c-grid-block__item--pearl {
	background-color: var(--pearl);
}

.c-grid-block__item--conch {
	background-color: var(--conch);
}

.c-grid-block__item--wtc-blue {
	background-color: var(--wtc-blue);
	color: #ffffff;
}

.c-grid-block__item--wtc-orange {
	background-color: var(--wtc-orange);
	color: #323333;
}

.c-grid-block__item--viridian {
	background-color: var(--viridian);
	color: var(--white);
}

.c-grid-block__button {
	background-color: transparent;
	color: #333333;
	border: 1px solid #333333;
	padding: 10px 20px;
	border-radius: 20px;
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
	line-height: 26px;
	height: 40px;
	text-decoration: none;
}

.c-grid-block__centertext {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	width: 236px;
	height: 40px;
}

.c-grid-block__toptext {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	width: 236px;
	height: 24px;
}