/* =============================================================================
 * Mobile navigation
 * postcss-bem-linter: define mobile-nav
============================================================================= */
.c-mobile-nav {
	display:flex;

	/* @media (--sm) { */
	display:none;
	position:fixed;
	bottom:24px;
	left:24px;
	z-index:2;
	flex-direction:column;

	/* } */
}
.c-mobile-nav__nav {
	margin-bottom:12px;
	border-radius:5px;
	box-shadow:0 0 20px 5px rgba(0, 0, 0, 0.2);
	background-color:var(--pearl-15);
}
.c-mobile-nav__toggle {
	display:flex;
	justify-content:center;
	align-items:center;
	flex-grow:0;
	min-width:auto;
	width:48px;
	height:48px;
	box-shadow:0 0 20px 5px rgba(0, 0, 0, 0.1);
	background-color:transparent;
	background-color:var(--white);
}
.c-mobile-nav__toggle-icon[class] {
	fill:var(--fuscous);
}
.c-mobile-nav__item:not(:last-of-type) {
	border-bottom:1px solid var(--pearl-50);
}
.c-mobile-nav__link {
	display:flex;
	align-items:center;
	padding:16px 20px;
	color:inherit;
	text-decoration:none;
}
.c-mobile-nav__action {
	margin:12px;
}
.c-mobile-nav__icon {
	margin-right:12px;
}
.c-mobile-nav .open-enter-active,
.c-mobile-nav .open-leave-active {
	transition:opacity 0.3s;
}
.c-mobile-nav .open-enter,
.c-mobile-nav .open-leave-to {
	opacity:0;
}
