/* =============================================================================
 * Icon
 * postcss-bem-linter: define icon
============================================================================= */
.c-icon {
	display:inline-block;
	flex-shrink:0;
	width:24px;
	height:24px;
	vertical-align:middle;
	fill: black;
}
.c-icon.slider-icon {
    fill: white;
}
.c-icon svg {
	width:100%;
	height:100%;
	margin:auto;
	pointer-events:none;
}
.c-icon--xxs {
	width:10px;
	height:10px;
}
.c-icon--xss {
	width:11px;
	height:14px;
	margin-bottom: 3px;
}
.c-icon--xs {
	width:16px;
	height:16px;
}
.c-icon--s {
	width:24px;
	height:24px;
}
.c-icon--mr-m {
	margin-right:12px;
}


/*
.c-icon--m {
	// Default; see first rule in this file
}
*/
.c-icon--l {
	width:32px;
	height:32px;
}
.c-icon--xl {
	width:48px;
	height:48px;
}
.c-icon--xxl {
	width:64px;
	height:64px;
}
.c-icon--xxxl {
	width:72px;
	height:72px;
}
.c-icon--ml {
	margin-left:5px;
}
.c-icon--mr {
	margin-right:5px;
}
.c-icon--flip-x {
	transform:scaleX(-1);
}
