/* =============================================================================
 * Quote
 *
 * postcss-bem-linter: define quote
============================================================================= */
.c-quote {
	background-color:var(--block-bg);
	color:var(--text);
}
.c-quote__inner {
	display:flex;
	position:relative;
	flex-wrap:wrap;
}
.c-quote blockquote {
	display:flex;
	flex-wrap:wrap;
	align-items:center;
	width:100%;
	margin:0;
	border:none;
}
.c-quote__text {
	width:100%;
}
.c-quote p {
	display:inline-block;
	margin-bottom:10px;
	font-size:var(--type-sz-60);
	line-height:var(--type-lh-60);
	font-family:var(--type-ff-header);
}
.c-quote p::before,
.c-quote p::after {
	content:"❞";
	vertical-align:top;
}
.c-quote footer {
	display:inline-block;
	flex:1;
	padding:0;
	background:none;
	font-size:var(--type-sz-20);
	line-height:var(--type-lh-20);

	@media (--md) {
		flex:1 1 auto;
		text-align:right;
	}
}
.c-quote footer::before {
	content:"\2014 \2009";
}
.c-quote .c-button {
	width:auto;
	margin-top:20px;
	margin-left:auto;
	text-align:center;
}
.c-quote--portrait p {
	@media (--md) {
		padding-left:125px;
	}
}
.c-quote--portrait img {
	display:inline-block;
	width:60px;
	height:60px;
	margin-right:20px;
	border-radius:50%;

	@media (--md) {
		position:absolute;
		top:0;
		left:15px;
		width:90px;
		height:90px;
		margin-right:0;
	}
}
.c-quote--image-background {
	position:relative;
}
.c-quote__background {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	background-position:50%;
	background-size:cover;
	background-repeat:no-repeat;
	opacity:0.25;
}
.c-quote--image-background footer {
	flex:1 1 auto;
}
.c-quote--image-background .c-quote__inner {
	z-index:2;
}
