/* =============================================================================
 * Loading
 *
 * postcss-bem-linter: define loading
============================================================================= */
.c-loading,
.c-loading::before,
.c-loading::after {
	width:2.5em;
	height:2.5em;
	border-radius:50%;
	animation:load7 1.8s infinite ease-in-out;
	animation-fill-mode:both;
}
.c-loading {
	position:relative;
	margin:80px auto;
	color:var(--hippie);
	font-size:0.625rem;
	text-indent:-9999em;
	transform:translateZ(0);
	animation-delay:-0.16s;
}
.c-loading::before,
.c-loading::after {
	position:absolute;
	top:0;
	content:"";
}
.c-loading::before {
	left:-3.5em;
	animation-delay:-0.32s;
}
.c-loading::after {
	left:3.5em;
}

@keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow:0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow:0 2.5em 0 0;
	}
}
