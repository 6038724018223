
.eventListContianer {
    max-width: 1700px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
}
 
.eventCard {
    display: flex;
    flex-flow: column;
    width: calc(24% - 2rem);
    min-width: 376px;
    margin: 1em 1.48em;
    background: #ffffff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}
 
    .eventCard a {
        color: #323333;
        text-decoration: none;
    }
 
.cardLink {
    padding: 0.3125em;
}
 
.top {
    display: flex;
    flex: 2;
}
 
    .top img {
        width: 100%;
    }
 
.bottom {
    display: flex;
    flex: 2.8;
    flex-flow: column;
    padding: 2.125rem 1.875rem;
}
 
.eventTitle {
    font-size: 1.6rem;
    font-weight: 600;
}
 
.personName {
    font-weight: 700;
    font-size: 1.125em;
    color: #434343;
}
 
.personText {
    font-size: 0.9375rem;
    font-style: italic;
}
 
.eventRegisterBtn {
    font-weight: 500;
    letter-spacing: 0.2px;
    border-radius: 10px;
    text-align: center;
    color: #323333;
    margin-bottom: 1rem;
    background: #f3ac73;
    border: solid 2px #f3ac73;
    display: inline-block;
    padding: 1.1em 0.875em;
    font-size: 17px;
    transition: all .2s linear;
}
 
a.eventRegisterBtn:hover {
    transform: scale(1.03);
    background: #fff;
}
 
.event-info {
    background: #485258;
    padding: 1.0625em 1.4375em;
}
 
.event-date,
.event-location,
.event-time {
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
}
 
    .event-location::before {
        content: url("data:image/svg+xml,%3Csvg id='Group_6288' data-name='Group 6288' xmlns='http://www.w3.org/2000/svg' height='22px' width='22px' fill='white' viewBox='0 0 17 22.5'%3E%3Cpath id='Path_3559' data-name='Path 3559' d='M8.5,0C3.8,0,0,3,0,8.3S8.5,22.5,8.5,22.5,17,13.5,17,8.3C17,3,13.2,0,8.5,0Zm0,19.4q-1.2-1.35-2.7-3.3C2.5,11.6,2,9.2,2,8.3A6.025,6.025,0,0,1,3.8,3.7,6.771,6.771,0,0,1,8.5,2a7.212,7.212,0,0,1,4.7,1.6A6.1,6.1,0,0,1,15,8.3c0,1-.5,3.3-3.9,7.9C10.2,17.4,9.2,18.6,8.5,19.4Z' class='cls-1'%3E%3C/path%3E%3Cpath id='Path_3560' data-name='Path 3560' d='M8.5,6A2.5,2.5,0,1,0,11,8.5,2.476,2.476,0,0,0,8.5,6Zm0,3A.5.5,0,1,1,9,8.5.472.472,0,0,1,8.5,9Z' class='cls-1'%3E%3C/path%3E%3C/svg%3E");
        margin-right: 10px;
    }
 
    .event-date::before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='22px' width='22px' fill='white' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.--%3E%3Cpath d='M128 0l0 16 0 48 192 0 0-48 0-16 32 0 0 16 0 48 64 0 32 0 0 32 0 64 0 32 0 288 0 32-32 0L32 512 0 512l0-32L0 192l0-32L0 96 0 64l32 0 64 0 0-48L96 0l32 0zM416 192L32 192l0 288 384 0 0-288zm0-96L32 96l0 64 384 0 0-64z'/%3E%3C/svg%3E");
        margin-right: 10px;
    }
 
    .event-time::before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='22px' width='22px' fill='white' viewBox='0 0 22 22'%3E%3Cpath id='shape_1_' d='M11,22A11,11,0,1,1,22,11,10.954,10.954,0,0,1,11,22Zm0-1.956A9.044,9.044,0,1,0,1.956,11,9.024,9.024,0,0,0,11,20.044ZM10.811,5.51a.978.978,0,0,1,1.956,0V11.5a.739.739,0,0,1-.367.733L7.877,15.777a1.112,1.112,0,0,1-1.344-.122,1.006,1.006,0,0,1,.122-1.467l4.156-3.178V5.51Z' class='cls-1'%3E%3C/path%3E%3C/svg%3E");
        margin-right: 10px;
    }