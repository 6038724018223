/* =============================================================================
 * Footer
 * postcss-bem-linter: define footer
============================================================================= */
.c-footer {
	padding:48px 0 76px 0;
	background-color:var(--gray-1);
	color:var(--white);
}
.c-footer__inner {
	display:flex;
	flex-direction:column;
	justify-content:space-around;

	@media (--sm) {
		flex-wrap:nowrap;
	}
}
.c-footer__channels {
	display:flex;
	justify-content:center;
	width:100%;
}
.c-footer__channels ul {
	display:flex;
	justify-content:center;
	width:100%;
	margin:0 0 20px 0;
	padding:0;
	list-style:none;

	@media (--sm) {
		justify-content:center;
	}
}
.c-footer__channels li:first-of-type {
	margin-left:-8px;
}
.c-footer__channels li:not(:last-of-type) {
	margin-right:24px;
}
.c-footer__channels svg {
	fill:var(--white);
}
.c-footer__channels a:hover svg {
	fill:var(--white);
}
.c-footer__content {
	display:flex;
	flex-direction:column;
	justify-content:center;
	flex-wrap:wrap;
	padding:0 20px;

	@media (--sm) {
		flex-direction:row;
		padding:0 40px;
	}

	@media (--md) {
		justify-content:center;
	}
}
.c-footer__links {
	margin-bottom:50px;
	border-left:1px solid var(--gray-3);
}
.c-footer__links ul {
	display:flex;
	flex-direction:column;
	justify-content:center;
	margin-bottom:20px;
	padding-right:40px;
	list-style:none;

	@media (--sm) {
		justify-content:flex-start;
	}
}
.c-footer__links h3 {
	padding:0 40px;

	@media (--sm-max) {
		margin-bottom:5px;
	}
}
.c-footer__links li {
	padding-top:10px;
	font-weight:500;
	font-size:1rem;

	@media (--sm) {
		margin-bottom:16px;
		padding:0 36px 0 0;
		font-size:1.125rem;
	}
}
.c-footer__links li:last-child {
	@media (--sm) {
		margin-bottom:0;
	}
}
.c-footer__logo {
	display:flex;
	justify-content:center;
	align-items:center;
	flex-shrink:0;
	width:100%;
	margin-bottom:10px;

	@media (--sm) {
		width:auto;
		margin:0;
	}
}
.c-footer__logo img {
	width:160px;
}
.c-footer__links a,
.c-footer__links a:visited {
	color:var(--white);
	text-decoration:none;
}
.c-footer a:hover {
	color:var(--white);
	text-decoration:underline;
}
.c-footer__links-contact a {
	text-decoration:underline;
}
.c-footer__info {
	text-align:center;

	@media (--sm) {
		text-align:left;
	}
}
.c-footer__info a {
	@media (--md) {
		margin-left:14px;
	}
}
.c-footer__info a::before {
	@media (--md) {
		margin-right:14px;
		content:"|";
	}
}
.c-footer__info .is-block-small {
	@media (--md-max) {
		display:block;
	}
}
.c-footer__info .no-break {
	@media (--md-max) {
		white-space:nowrap;
	}
}
.c-footer__cookies-link,
.c-footer__cookies-link:visited {
	color:var(--white);
}


.c-footernew__logo {
	display: inline-flex;
    color: #fff;
    font-size: 16px;
    gap: 1rem;
    align-items: center;
    text-decoration: none;
	margin: 38px auto;
}

.c-footernew__logo > p{
	margin: 16px 0;
}

.c-footer.c-footernew__logo_visible {
	padding: 48px 0 0 0;
}
