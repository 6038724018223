/* =============================================================================
 * Pagination
 * postcss-bem-linter: define pagination
============================================================================= */
.c-pagination__label {
	display:block;
	margin-bottom:16px;
	text-align:center;
}
.c-pagination ul {
	margin:0;
	padding:0;
	list-style:none;
	text-align:center;
}
.c-pagination li {
	display:none;
	margin:0;

	@media (--sm) {
		display:inline-block;
	}
}
.c-pagination li.c-pagination__previous,
.c-pagination li.c-pagination__next {
	display:inline-block;
	margin:0 10px;
	border-radius:3px;
	background-color:var(--block-bg-contrast);

	@media (--sm) {
		display:inline-block;
		margin:0;
		background-color:transparent;
	}
}
.c-pagination .c-pagination__previous a::before {
	margin-right:10px;
	content:"◄";
}
.c-pagination .c-pagination__next a::after {
	margin-left:10px;
	content:"►";
}
.c-pagination a {
	display:inline-block;
	min-width:16px;
	padding:6px 12px 4px 12px;
	border-radius:3px;
	color:var(--gray-1);
	text-align:center;
	text-decoration:none;
}
.c-pagination a:hover {
	background-color:var(--block-bg-contrast);
	color:inherit;
}


/* Current page
----------------------------------------------------------------------------- */
.c-pagination__current a {
	background:var(--control-bg);
	color:var(--control-text);
}
