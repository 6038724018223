/* =============================================================================
 * Contacts
 *
 * postcss-bem-linter: define contacts
============================================================================= */
.contactsblock {
	background:var(--block-bg);
}
.c-contacts {
	position:relative;
	top:-5px;
	background:var(--block-bg);
	color:var(--text);
}
.c-contacts__inner {
	display:flex;
	flex-wrap:wrap;
}
.c-contacts__inner > p {
	width:100%;
}
.c-contacts__list {
	display:flex;
	flex-wrap:wrap;
	margin-top:20px;
	padding:0;

	@media(--md) {
		margin-top:60px;
	}
}
.c-contacts__main-title {
	width:100%;
	margin:0 0 10px 0;
}
.c-contacts__item {
	display:flex;
	margin-bottom:40px;

	@media (--sm) {
		margin-bottom:0;
	}
}
.c-contacts__item:not(:last-child) {
	margin-right:50px;
}
.c-contacts a {
	color:inherit;
}
.c-contacts img {
	width:60px;
	height:60px;
	margin-right:15px;
	border-radius:50%;

	@media(--md) {
		width:90px;
		height:90px;
		margin-right:25px;
	}
}
.c-contacts__content p:last-child {
	margin-bottom:0;
}
.c-contacts__info {
	max-width:300px;
}
.c-contacts__heading {
	margin-bottom:6px;
}
.c-contacts__title {
	display:inline-block;
	margin-bottom:6px;
	font-style:italic;
}
.c-contacts address {
	font-style:normal;
}
