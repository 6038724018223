/* =============================================================================
 * Editorial
 *
 * postcss-bem-linter: define editorial
============================================================================= */
.s-editorial--editor {
	padding:24px;
}
picture + .s-editorial {
	margin-top:16px;
}
