/* =============================================================================
 * Flex align
 *
 * postcss-bem-linter: define flex-align
============================================================================= */
.t-flex-align--start {
	align-items:flex-start;
}
.t-flex-align--end {
	align-items:flex-end;
}
.t-flex-align--center {
	align-items:center;
}
.t-flex-align--stretch {
	align-items:stretch;
}
.t-flex-align--baseline {
	align-items:baseline;
}
