/* =============================================================================
 * Info
 *
 * postcss-bem-linter: define info
============================================================================= */
.c-info {
	background:var(--block-bg);
	color:var(--text);
	font-size:var(--type-sz-20);
	line-height:var(--type-lh-20);
	text-align:center;
}
.c-info__inner {
	@media(--md) {
		padding:0 100px;
	}
}
.c-info__icons span {
	margin:0 20px 20px 20px;
	fill:var(--icon-subtle);
}
