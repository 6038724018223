/* =============================================================================
 * Article
 *
 * postcss-bem-linter: define article
============================================================================= */
.c-article--share-bar .c-article__inner {
	position:relative;
}
.c-article__feature {
	min-height:150px;

	@media(--sm) {
		min-height:110px;
		margin-bottom:40px;
	}
}
.c-article__feature-title {
	@media(--sm) {
		margin-bottom:10px;
	}
}
.c-article__date {
	font-size:var(--type-sz-5);
	line-height:var(--type-lh-5);
}
.c-article__lead {
	margin-bottom:24px;
	font-weight:bold;
}
.c-article__lead p {
	margin:0;
}
.c-article__divider {
	width:60px;
	height:1px;
	margin-bottom:20px;
	margin-left:0;
	background-color:var(--gray-1);
}
.c-article__feature-image img {
	float:right;
	width:74px;
	height:74px;
	margin:5px 0 20px 15px;
	border-radius:50%;
	box-shadow:0 4px 10px rgba(0, 0, 0, 0.15);

	@media (--sm) {
		width:111px;
		height:111px;
		margin-top:-30px;
		margin-left:50px;
	}
}
.c-article__blockquote {
	position:relative;
	padding:0 50px;

	/* font-style:italic; */
	line-height:26px;
	-webkit-margin-start:0;
	margin-inline-start:0;
	-webkit-margin-end:0;
	margin-inline-end:0;
}
.c-article__blockquote::before {
	position:absolute;
	top:15%;
	left:15px;
	width:2px;
	height:70%;
	background-color:var(--gray-3);
	content:"";
}
.c-article a {
	color:var(--calypso);
}
.c-article hr {
	margin-bottom:20px;
}
.c-article h1 {
	margin-bottom:24px;
	font-size:var(--type-sz-100);
	line-height:var(--type-lh-100);
}
.c-article h2 {
	font-size:var(--type-sz-60);
	line-height:var(--type-lh-60);
}
