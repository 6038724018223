/* =============================================================================
 * Padding
 *
 * postcss-bem-linter: define padding
============================================================================= */
.t-text-align--center {
	text-align:center;
}
.t-text-align--left {
	text-align:left;
}
.t-text-align--right {
	text-align:right;
}
