/* =============================================================================
 * Excerpt
 *
 * postcss-bem-linter: define excerpt
============================================================================= */
.c-popup--overlay {
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:100;
	background-color:rgba(255, 255, 255, 0.5);
	transition:background-color 0.4s ease-in-out;
}
.c-popup__toggle {
	position:fixed;
	right:24px;
	bottom:24px;
	z-index:2;
	justify-content:space-between;
	height:48px;
	padding:12px;
	padding:8px 22px;
	background-color:var(--fuscous);
	font-weight:700;
}
.c-popup__content {
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:5;
	overflow-x:hidden;
	overflow-y:scroll;
	padding:24px 50px;
	background-color:var(--pearl-15);

	@media (--sm) {
		left:auto;
		width:420px;
	}
}
.c-popup__content::before,
.c-popup__content::after {
	position:fixed;
	top:0;
	right:24px;
	z-index:4;
	width:100%;
	height:40px;
	background-image:linear-gradient(to top, rgba(247, 245, 243, 0), var(--pearl-15));
	content:"";

	@media (--sm) {
		width:380px;
	}
}
.c-popup__content::after {
	top:auto;
	bottom:0;
	background-image:linear-gradient(to bottom, rgba(247, 245, 243, 0), var(--pearl-15));
}
.c-popup__close {
	display:flex;
	position:fixed;
	top:24px;
	right:24px;
	z-index:5;
	justify-content:center;
	align-items:center;
	width:32px;
	height:32px;
	border:none;
	border-radius:3px;
	background-color:transparent;
	cursor:pointer;
}
.c-popup__close:hover {
	background-color:var(--fuscous-15);
}
.c-popup__close svg {
	fill:var(--fuscous);
}
.c-popup__inner {
	position:relative;
	padding:24px 0;
}
.c-popup__content::-webkit-scrollbar-track {
	background-color:transparent;
}
.c-popup__content::-webkit-scrollbar {
	width:6px;
	background-color:transparent;
}
.c-popup__content::-webkit-scrollbar-thumb {
	border-radius:10px;
	background-color:var(--fuscous-50);
}
.c-popup .toggle-enter-active,
.c-popup .toggle-leave-active {
	transition:transform 0.3s;
	transform:translateX(0);
}
.c-popup .toggle-enter,
.c-popup .toggle-leave-to {
	transform:translateX(420px);
}
