/* =============================================================================
 * Padding
 *
 * postcss-bem-linter: define padding
============================================================================= */
.t-padding--v-none[class] {
	padding-top:0;
	padding-bottom:0;

	@media (--md) {
		padding-top:0;
		padding-bottom:0;
	}
}
.t-padding--v-s[class] {
	padding-top:10px;
	padding-bottom:10px;

	@media (--md) {
		padding-top:20px;
		padding-bottom:20px;
	}
}
.t-padding--v[class] {
	padding-top:10px;
	padding-bottom:10px;

	@media (--sm) {
		padding-top:20px;
		padding-bottom:20px;
	}

	@media (--md) {
		padding-top:40px;
		padding-bottom:40px;
	}
}
.t-padding--v-m[class] {
	padding-top:20px;
	padding-bottom:20px;

	@media (--sm) {
		padding-top:30px;
		padding-bottom:30px;
	}

	@media (--md) {
		padding-top:60px;
		padding-bottom:60px;
	}
}
.t-padding--v-l[class] {
	padding-top:30px;
	padding-bottom:30px;

	@media (--sm) {
		padding-top:50px;
		padding-bottom:50px;
	}

	@media (--md) {
		padding-top:80px;
		padding-bottom:80px;
	}
}
.t-padding--v-xl[class] {
	padding-top:40px;
	padding-bottom:40px;

	@media (--sm) {
		padding-top:60px;
		padding-bottom:60px;
	}

	@media (--md) {
		padding-top:100px;
		padding-bottom:100px;
	}
}
.t-padding--v-xxl[class] {
	padding-top:60px;
	padding-bottom:60px;

	@media (--sm) {
		padding-top:80px;
		padding-bottom:80px;
	}

	@media (--md) {
		padding-top:120px;
		padding-bottom:120px;
	}
}
.t-padding--h[class] {
	padding-right:10px;
	padding-left:10px;

	@media (--sm) {
		padding-right:20px;
		padding-left:20px;
	}

	@media (--md) {
		padding-right:40px;
		padding-left:40px;
	}
}
.t-padding--h-m[class] {
	padding-right:20px;
	padding-left:20px;

	@media (--sm) {
		padding-right:30px;
		padding-left:30px;
	}

	@media (--md) {
		padding-right:60px;
		padding-left:60px;
	}
}
.t-padding--h-l[class] {
	padding-right:30px;
	padding-left:30px;

	@media (--sm) {
		padding-right:50px;
		padding-left:50px;
	}

	@media (--md) {
		padding-right:80px;
		padding-left:80px;
	}
}
.t-padding--h-xl[class] {
	padding-right:40px;
	padding-left:40px;

	@media (--sm) {
		padding-right:60px;
		padding-left:60px;
	}

	@media (--md) {
		padding-right:100px;
		padding-left:100px;
	}
}
.t-padding--top[class] {
	padding-top:10px;

	@media (--sm) {
		padding-top:20px;
	}

	@media (--md) {
		padding-top:40px;
	}
}
.t-padding--top-m[class] {
	padding-top:20px;

	@media (--sm) {
		padding-top:30px;
	}

	@media (--md) {
		padding-top:60px;
	}
}
.t-padding--top-l[class] {
	padding-top:30px;

	@media (--sm) {
		padding-top:50px;
	}

	@media (--md) {
		padding-top:80px;
	}
}
.t-padding--top-xl[class] {
	padding-top:40px;

	@media (--sm) {
		padding-top:60px;
	}

	@media (--md) {
		padding-top:100px;
	}
}
.t-padding--top-xxl[class] {
	padding-top:60px;

	@media (--sm) {
		padding-top:80px;
	}

	@media (--md) {
		padding-top:120px;
	}
}
.t-padding--bottom[class] {
	padding-bottom:10px;

	@media (--sm) {
		padding-bottom:20px;
	}

	@media (--md) {
		padding-bottom:40px;
	}
}
.t-padding--bottom-m[class] {
	padding-bottom:20px;

	@media (--sm) {
		padding-bottom:30px;
	}

	@media (--md) {
		padding-bottom:60px;
	}
}
.t-padding--bottom-l[class] {
	padding-bottom:30px;

	@media (--sm) {
		padding-bottom:50px;
	}

	@media (--md) {
		padding-bottom:80px;
	}
}
.t-padding--bottom-xl[class] {
	padding-bottom:40px;

	@media (--sm) {
		padding-bottom:60px;
	}

	@media (--md) {
		padding-bottom:100px;
	}
}
.t-padding--bottom-xxl[class] {
	padding-bottom:60px;

	@media (--sm) {
		padding-bottom:80px;
	}

	@media (--md) {
		padding-bottom:120px;
	}
}
.t-padding--has-breadcrumbs[class] {
	padding-top:120px;
}
