/* =============================================================================
 * CRM
 *
 * postcss-bem-linter: define crm
============================================================================= */
.s-crm div[class^="col"] {
	width:100% !important;
}
.s-crm #simpliform h1,
.s-crm #simpliform h2,
.s-crm #simpliform h3 {
	font-family:var(--type-ff-header);
}
.s-crm #simpliform .form-field {
	position:relative;
	float:left;
	min-height:1px;
	width:100%;
	padding:0;
}
.s-crm #simpliform .form-control {
	width:100%;
	height:auto;
	padding:12px 10px;
	padding:10px;
	border:1px solid var(--gray-3);
	border-radius:3px;
	background:var(--white) !important;
	color:var(--black);
	font-size:var(--type-sz-default);
	line-height:var(--type-lh-default);
	font-family:var(--type-ff-default);
}
.s-crm #simpliform .form-control:focus {
	outline:var(--black) auto 1px;
	border:1px solid var(--gray-3);
	box-shadow:none;
}
.s-crm #simpliform .control-label {
	font-weight:normal;
	opacity:0.9;
}
.s-crm #simpliform .btn {
	display:inline-flex;
	justify-content:center;
	align-items:center;
	min-width:90px;
	padding:8px 12px;
	border:0;
	border-radius:3px;
	box-shadow:1px 1px 2px 1px rgba(0, 0, 0, 0.05);
	background-color:var(--control-bg);
	color:var(--control-text);
	font:inherit;
	font-weight:500;
	font-size:var(--type-sz-default);
	line-height:inherit;
	line-height:var(--type-lh-default);
	text-decoration:none;
	cursor:pointer;

	@media (--sm) {
		min-width:120px;
		padding:10px 12px;
	}
}
.s-crm #simpliform .btn:hover,
.s-crm #simpliform .btn:focus,
.s-crm #simpliform .btn:active,
.s-crm #simpliform .btn[disabled]:hover,
.s-crm #simpliform .btn[disabled]:focus,
.s-crm #simpliform .btn[disabled]:active {
	background-color:var(--control-bg);
	opacity:0.9;
}
.s-crm #simpliform .has-error .help-block,
.s-crm #simpliform .has-error .control-label,
.s-crm #simpliform .has-error .radio,
.s-crm #simpliform .has-error .checkbox,
.s-crm #simpliform .has-error .radio-inline,
.s-crm #simpliform .has-error .checkbox-inline,
.s-crm #simpliform .has-error.radio label,
.s-crm #simpliform .has-error.checkbox label,
.s-crm #simpliform .has-error.radio-inline label,
.s-crm #simpliform .has-error.checkbox-inline label {
	color:var(--error);
}
.s-crm #simpliform .has-error .form-control {
	border-color:var(--error);
}
