/* =============================================================================
 * Custom checkbox
 *
 * postcss-bem-linter: define custom-checkbox
============================================================================= */
.c-custom-checkbox {
	display:flex;
	position:relative;
	align-items:center;
}
.c-custom-checkbox__input {
	position:absolute;
	width:1px;
	height:1px;
	overflow:hidden;
	clip:rect(1px, 1px, 1px, 1px);
	padding:0;
	border:0;
	white-space:nowrap;
}
.c-custom-checkbox__label {
	display:block;
	position:relative;
	margin:0 0 0 40px;
	cursor:pointer;
}
.c-custom-checkbox__label::before {
	display:inline-block;
	position:absolute;
	left:-32px;
	width:19px;
	height:19px;
	border:1px solid var(--control-bg);
	border-radius:5px;
	content:"";
}
.c-custom-checkbox__label::after {
	display:inline-block;
	position:absolute;
	top:3px;
	left:-29px;
	width:13px;
	height:13px;
	border-radius:3px;
	content:"";
}
.c-custom-checkbox__input:checked + .c-custom-checkbox__label::after {
	background-color:var(--control-bg);
}
