/* =============================================================================
 * Object
 * postcss-bem-linter: define object
============================================================================= */
.c-object {
	position:relative;
	margin-bottom:40px;
	text-align:center;

	@media (--xs) {
		margin-bottom:0;
	}
}
.c-object__media img {
	width:100%;
}
.c-object__body{
	text-align: left;
}
.c-object a {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:2;
}
.c-object__content {
	position:relative;
}
.c-object__content > *:first-child {
	max-width:100%;
	margin-right:auto;
	margin-left: 0;
	margin-top: 12px;
}
.c-object__head {
	display:flex;
	flex-direction:column;
	justify-content:center;
	margin-bottom:10px;
}
.c-object__title {
	display:inline-block;
	margin-bottom:8px;
	background-color:var(--white);
	font-weight:bolder;
	font-size:var(--type-sz-20);
	line-height:var(--type-lh-20);
	font-family:var(--type-ff-default);
	text-align: left;
}
.c-object__byline {
	color:var(--gray-1);
	font-size:var(--type-sz-10);
	line-height:var(--type-lh-10);
	text-align: left;
}
.c-object__byline {
	font-style:italic;
}
.c-object__icons {
	text-align: left;
	justify-content:left;
	align-items:center;
	margin-bottom:10px;
}
.c-object__icon-wrapper {
	display: inline-block;
	margin-bottom: 4px;
	padding: 4px 8px;
	border-radius:20%;
	background-color:var(--gray-4);
	line-height:1;
}
.c-object__icon {
	fill:var(--gray-1);
}
.c-object__icons-more {
	display:inline-flex;
	justify-content:center;
	align-items:center;
	width:16px;
	height:16px;
	color:var(--gray-1);
	font-weight:300;
	font-size:var(--type-sz-5);
	line-height:1;
}
.c-object__icons span:not(:last-child) {
	margin-right:4px;
}
.c-object__text {
	margin:0;
	font-size:var(--type-sz-50);
	line-height:var(--type-lh-50);
	font-family:var(--type-ff-header);
}

/* Lazy loading images */
.c-object__media img[lazy="loaded"] {
	opacity:0;
	animation-name:fadein;
	animation-duration:0.5s;
	animation-timing-function:ease-out;
	animation-iteration-count:1;
	animation-direction:normal;
	animation-fill-mode:forwards;

	@keyframes fadein {
		0% {
			opacity:0;
		}
		100% {
			opacity:1;
		}
	}
}
