/* =============================================================================
 * A-Z
 *
 * postcss-bem-linter: define a-z
============================================================================= */
.c-a-z__list {
	display:flex;
	justify-content:space-between;
	margin:0;
	padding:0;
	list-style-type:none;
	color:var(--gray-6);
}
