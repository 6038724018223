/* =============================================================================
 * Big select
 * postcss-bem-linter: define big-select
============================================================================= */
.c-big-select {
	width:auto;
	padding:8px 22px 2px 16px;
	border:none;
	border-radius:5px;
	box-shadow:0 4px 10px 0 rgba(0, 0, 0, 0.15);
	background-color:var(--calypso);
	background-image:url("/gui/images/icons/select-arrow.svg");
	background-position:92% center;
	background-size:16px;
	background-repeat:no-repeat;
	color:var(--white);
	font-size:var(--type-sz-80);
	line-height:var(--type-lh-80);
	font-family:var(--type-ff-header);
	-webkit-appearance:none;

	@media (--xs) {
		font-size:var(--type-sz-120);
		line-height:var(--type-lh-120);
		font-family:var(--type-ff-header);
	}
}
.c-big-select option {
	font-size:var(--type-sz-40);
	line-height:var(--type-lh-40);
}
