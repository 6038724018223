/* =============================================================================
 * Text
 *
 * postcss-bem-linter: define text
============================================================================= */
.c-text {
	background-color:var(--block-bg);
	color:var(--text);
}
.c-text__main-img {
	width:100%;
	margin-bottom:30px;
}
.c-text__content {
	display:flex;
	justify-content:space-evenly;
	flex-wrap:wrap;
}
.c-text__title {
	width:100%;
	margin-bottom:32px;
}
.c-text__title--center {
	text-align:center;
}
.c-text__title--xl {
	@media (--md) {
		padding-right:20%;
		padding-left:20%;
		font-size:var(--type-sz-120);
		line-height:var(--type-lh-120);
		font-family:var(--type-ff-header);
	}
}
.c-text__byline {
	margin-bottom:10px;
	font-style:italic;
}
.c-text__read-more {
	display:block;
	margin-top:6px;
}
.c-text__block {
	width:100%;
	margin-top:24px;

	@media (--sm) {
		flex:1 1 0;
		margin-top:0;
		margin-right:30px;
	}
}
.c-text__block:first-of-type {
	margin-top:0;
}
.c-text--vertical-divider .c-text__block:not(:last-child) {
	position:relative;
	margin-bottom:26px;
	padding-bottom:50px;

	@media (--sm) {
		margin-right:30px;
		margin-bottom:0;
		padding-right:30px;
		padding-bottom:0;
	}

	@media (--md) {
		margin-right:60px;
		padding-right:60px;
	}
}
.c-text--vertical-divider .c-text__block:not(:last-child)::after {
	position:absolute;
	bottom:0;
	left:0;
	width:50%;
	height:1px;
	background-color:var(--icon-subtle);
	content:"";
	opacity:0.5;

	@media (--sm) {
		top:10%;
		right:0;
		left:unset;
		width:1px;
		height:80%;
	}
}
.c-text__block-img {
	width:100%;
	margin-bottom:30px;
}
.c-text__block p {
	margin-bottom:30px;
}
.c-text__block p + p {
	margin-top:-10px;
}
.c-text__block-title {
	margin:0 0 10px 0;
	word-break:break-all;
}
.c-text__block:last-child {
	margin-right:0;
}
.c-text__block p:last-child {
	margin-bottom:0;
}
.c-text__block ul {
	display:flex;
	flex-direction:column;
	margin:0;
	padding:0;
}
.c-text__block li {
	display:flex;
	align-items:center;
	margin-bottom:20px;
	list-style-type:none;
}
.c-text__block li::before {
	margin-top:1px;
	padding-right:12px;
	color:var(--icon-subtle);
	font-size:160%;
	content:"•";
}
.c-text__block ul.no-bullets li::before {
	display:none;
}
.c-text__block--xl {
	flex:1.6;
}
