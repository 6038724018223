/* =============================================================================
 * Contact-person
 *
 * postcss-bem-linter: define contact-person
============================================================================= */
.c-contact-person {
	position:relative;
	background:var(--block-bg);
	color:var(--text);
}
.c-contact-person__inner {
	display:flex;
	justify-content:center;
	text-align:center;
	flex-flow:column;

	@media (--sm) {
		display:grid;
		grid-column-gap:0;
		grid-row-gap:0;
		grid-template-columns:2fr 1fr;
		grid-template-rows:repeat(2, 1fr);
		min-height:250px;
		text-align:left;
	}
}
.c-contact-person__content {
	display:flex;
	justify-content:flex-end;
	flex-flow:column;

	@media (--sm) {
		margin-bottom:30px;
	}
}
.c-contact-person__image {
	display:flex;
	align-items:center;
	margin:20px auto;

	@media (--sm) {
		justify-content:flex-end;
		align-items:flex-end;
		grid-area:1 / 2 / 3 / 3;
		margin:0;
	}
}
.c-contact-person__image img {
	max-width:180px;
	max-height:180px;
	border-radius:50%;

	@media (--sm) {
		max-width:250px;
		max-height:250px;
	}
}
.c-contact-person a:not(.c-chip) {
	color:var(--text-link);
}
.c-contact-person address {
	margin-top:6px;
	font-style:normal;
}
.c-contact-person__title {
	margin:0 0 6px 0;
}
.c-contact-person p {
	margin-bottom:0;
}
.c-contact-person--offset {
	padding-bottom:24px;
}
.c-contact-person--offset .c-contact-person__inner {
	display:flex;
	justify-content:flex-start;
	align-items:center;
	min-height:auto;
	text-align:left;
	flex-flow:row;
}
.c-contact-person--offset .c-contact-person__image {
	display:flex;
	align-items:baseline;
	align-items:center;
	margin:20px 0 0;

	@media (--sm) {
		margin-top:-70px;
	}
}
.c-contact-person--offset .c-contact-person__image img {
	max-width:80px;
	max-height:80px;
	border-radius:50%;

	@media (--xs) {
		max-width:100px;
		max-height:100px;
	}

	@media (--sm) {
		max-width:140px;
		max-height:140px;
	}
}
.c-contact-person--offset .c-contact-person__contact {
	margin-top:20px;
	margin-left:12px;

	@media (--sm) {
		margin-top:24px;
		margin-left:36px;
	}
}


/* Offset-extended */
.c-contact-person--offset-extended .c-contact-person__inner {
	display:flex;
	flex-direction:row;
	justify-content:left;
	flex-wrap:wrap;
	margin-bottom:0;
	padding:0;
	background-color:var(--block-bg-contrast);


	@media (--md) {
		justify-content:center;
		max-width:calc(var(--limiter-full) - 120px);
		width:calc(100% - 120px);
		margin-top:-60px;
		padding:50px 40px;
		background-color:var(--block-bg);
	}

	@media (--lg) {
		padding:70px 60px  70px 100px;
	}
}
.c-contact-person--offset-extended .c-contact-person__content {
	position:relative;
	justify-content:left;
	flex-basis:100%;
	padding:3em 30px 4em;
	text-align:left;

	@media (--md-max) {
		background-color:var(--block-bg);
	}

	@media (--md) {
		flex-basis:60%;
		max-width:60%;
		margin:0;
		padding:0 4em 0 0;
		font-weight:300;
		font-size:1.1em;
		line-height:1.35;
	}

	@media (--lg) {
		padding:0 6em 0 0;
		font-size:1.3em;
	}
}
.c-contact-person--offset-extended .c-contact-person__content .c-contact-person__title {
	line-height:1.1;

	@media (--md) {
		font-size:2.5em;
	}
}
.c-contact-person__byline {
	margin:0 0 0.5em;
	line-height:1.1;

	@media (--md) {
		margin:0.5em 0;
	}
}
.c-contact-person--offset-extended .c-contact-person__content .c-contact-person__title::after {
	@media (--md-max) {
		display:block;
		width:140px;
		height:1px;
		margin:0.5em 0;
		background-color:var(--text);
		content:"";
	}
}
.c-contact-person--offset-extended .c-contact-person__content::after {
	@media (--md) {
		position:absolute;
		top:50%;
		right:2em;
		width:1px;
		height:calc(100% - 1.5em);
		background-color:var(--text);
		content:"";
		transform:translateY(-50%);
	}

	@media (--lg) {
		right:3em;
	}
}
.c-contact-person--offset-extended .c-contact-person__contact {
	display:flex;
	flex-direction:column;
	justify-content:center;
	max-width:calc(60% - 30px);
	color:var(--text-contrast);
	text-align:left;

	@media (--md-max) {
		padding:1.5em 0;
	}

	@media (--md) {
		flex-basis:25%;
		max-width:25%;
		margin:0;
		color:var(--text);
	}
}
.c-contact-person--offset-extended .c-contact-person__image {
	align-items:center;
	max-width:calc(40% - 30px);
	margin:25px 30px;

	@media (--md) {
		justify-content:center;
		flex-basis:15%;
		max-width:15%;
		margin:0;
	}
}
.c-contact-person--offset-extended .c-contact-person__image img {
	max-width:90px;
	max-height:90px;

	@media (--md) {
		max-width:140px;
		max-height:140px;
		width:calc(100% - 20px);
		height:auto;
		margin-right:20px;
	}
}
.c-contact-person--offset-extended .c-contact-person__contact a {
	color:inherit;
}
.c-contact-person--offset-extended {
	background-color:transparent;
}
