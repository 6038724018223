/* =============================================================================
 * Image scroller
 * postcss-bem-linter: define image-scroller
============================================================================= */
.c-image-scroller {
	position:relative;
	width:100%;
	overflow:-moz-scrollbars-none; /* Firefox no scrollbar  */
	overflow-x:scroll;
	text-align:left;
	-ms-overflow-style:none; /* IE 10+ no scrollbar */

	@media (--md) {
		overflow:hidden;
	}
}
.c-image-scroller::-webkit-scrollbar { /* Webkit (Chrome etc) no scrollbar */
	width:0 !important;
}
.c-image-scroller img {
	border-radius:5px;
	box-shadow:0 4px 10px 0 rgba(0, 0, 0, 0.07);
}
.c-image-scroller__image-row {
	position:relative;
}
.c-image-scroller__text-row,
.c-image-scroller__thumbnails {
	display:flex;
	position:relative;
	height:100%;
	padding:10px 0 3px;
	transition:all 0.5s;
}
.c-image-scroller__thumbnail-button {
	flex:0 0 30%;
	height:100%;
	margin-right:10px;
	padding:0;
	outline:none;
	border:none;
	border-radius:5px;
	background:transparent;

	@media (--sm) {
		flex:0 0 22%;
	}
}
.c-image-scroller__text {
	margin-bottom:0;
	font-weight:normal !important;
}
.c-image-scroller__text-2 {
	margin-bottom:0;
}
.c-image-scroller__thumbnail-button--current,
.c-image-scroller__thumbnail-button:focus {
	background-color:var(--white);
}
.c-image-scroller__thumbnail-button--current .c-image-scroller__thumbnail,
.c-image-scroller__thumbnail-button:focus .c-image-scroller__thumbnail {
	opacity:0.25;
}
.c-image-scroller__thumbnail {
	margin-right:10px;
}
.c-image-scroller__thumbnail-nav {
	display:none;
	position:absolute;
	top:0;
	z-index:1;
	height:100%;
	padding-top:10px;

	@media (--md) {
		display:flex;
		align-items:center;
	}
}
.c-image-scroller__thumbnail-nav--left {
	left:0;
	margin-left:10px;
}
.c-image-scroller__thumbnail-nav--right {
	right:0;
	margin-right:10px;
}
.c-image-scroller__thumbnail-nav button:focus {
	outline:none;
}
