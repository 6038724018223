.c-contact-worker__list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 2rem;
  margin-bottom: 5rem;
}
.c-contact-coworker__item {
  flex: 25%;
  background-color: var(--calypso-15);
  border-top-left-radius: 15px;
}
.c-contact-coworker__img {
  border-radius: 50%;
  border: 1px solid #ddd;
  width: 104px;
  height: 100px;
  position: absolute;
  top: -10px;
  left: -15px;
}
.c-contact-worker__hits-heading {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: end;
}
.c-contact-worker__img-wrapper {
  display: flex;
  position: relative;
}
.c-contact-worker__header-wrapper {
  margin-left: 100px;
  margin-top: 1rem;
  height: 60px;
}
.c-contact-coworker__heading {
  margin-bottom: 5px;
}
.c-contact-coworker__info {
  padding: 15px;
  margin-top: 1.3rem;
  font-style: normal;
}
.c-contact-coworker__info svg {
  margin-left: 10px;
  cursor: pointer;
}
.c-contact-coworker__info p {
  margin-bottom: 0;
}
.c-contact-coworker__number,
.c-contact-coworker__email {
  display: flex;
}
/* .c-contact-coworker__info address div:first-child {
  margin-bottom: 1px;
} */

.c-contact-worker__pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.c-contact-worker__display-items,
.c-contact-worker__total-items {
  font-weight: 700;
  color: var(--hippie);
}
.c-contact-worker__pagination-wrapper a {
  margin-top: 15px;
  margin-bottom: 2rem;
}

@media (max-width: 992px) {
  .c-contact-coworker__item {
    flex: 40%;
  }
}
@media (max-width: 768px) {
  .c-contact-coworker__item {
    flex: 100%;
  }
}

@media (min-width: 992px) {
  .c-contact-coworker__item {
    max-width: 33%;
  }
}

.c-contact-worker--overlay {
	/* position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0; */
	z-index:100;
	/* height:calc(100vh + 95px); */

	@media (--sm) {
		background-color:rgba(0, 0, 0, 0.1);
		transition:all 0.8s;
	}
}

.c-contact-worker__sticky-top {
	background-color:#fafafa;
  margin-top: 30px;
  margin-right: 20px;
  margin-left: auto;
  float: right;
  /* float: right;

	/* @media (--sm) {
		position:-webkit-sticky;
		position:sticky;
		top:0;
	} */
}

.c-contact-worker__toggle {
	display:inline-block;
	width:200px;
	height:100%;
	padding:0;
	border:none;
	background-color: transparent;
    color: black;
	cursor:pointer;
}

.c-contact-worker__content {
  position: fixed;
  top: 0;
  right: 39vw;
  bottom: 0;
  left: auto;
  width: 420px;
  height: 100%;
  z-index: 10;
  overflow-x: hidden;
  background-color: #fafafa;
  padding-bottom: 70px;

  /* @media (--sm) {
    top: 0;
    left: auto;
    width: 574px;
  } */
}

@media (max-width: 2200px) {
  .c-contact-worker__content {
    right: 38vw;
  }
}

@media (max-width: 2000px) {
  .c-contact-worker__content {
    right: 37vw;
  }
}

@media (max-width: 1800px) {
  .c-contact-worker__content {
    right: 36vw;
  }
}

@media (max-width: 1500px) {
  .c-contact-worker__content {
    right: 33vw;
  }
}

@media (max-width: 1200px) {
  .c-contact-worker__content {
    right: 29vw;
  }
}

@media (max-width: 1024px) {
  .c-contact-worker__content {
    right: 28vw;
  }
}

@media (max-width: 820px) {
  .c-contact-worker__content {
    right: 24vw;
  }
}

@media (max-width: 767px) {
  .c-contact-worker__content {
    -webkit-overflow-scrolling: touch;
    width: 100%;
    right: 0;
  }
}

.dim-effect {
  opacity: 0.5;
  transition: opacity 0.5s ease; /* Smooth transition for dimming */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(84, 123, 152, 0.9);
  opacity: 0;
  z-index: 4;
  display: none;
}

.overlay.show {
  display: block;
  opacity: 1;
}

@media (max-width: 767px) {
  .overlay {
    width: 0%;
  }
}

.contact-worker-card-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: 20vh;
  padding-top: 150px;
}

.contact-worker-card__img {
  border-radius: 100%;
  border: 1px solid #ddd;
  width: 154px;
  height: 150px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 9%;
  z-index: 1;
}


.contact-worker-card-background {
  width: 350px;
  padding: 10px;
  text-align: left;
  background-color:var(--calypso-15);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.coworker-card-contactinformation {
  padding-top: 11vh;
}

.coworkeritem_top {
  font-style: normal;
  padding-left: 8px;
}

@media (max-width: 767px) {
  .coworkeritem_top {
    font-size: var(--type-sz-80);
  }
}

.contact-text-row {
  padding-top: 8px;
  padding-left: 8px;
  font-size: 16px;
  line-height: 1.4;
  font-style: normal;
}

.contact-text-row:first-child {
  padding-top: 10px;
}

.contactdepartmentcontainer {
  margin-top: 40px;
  margin-bottom: 8px;
}

.contactworkerarea {
  display: flex;
  justify-content: flex-start;
  font-style: normal;
}

.contactworkerarea-text-row {
  padding-left: 8px;
  font-size: 16px;
  line-height: 1.4;
  font-style: normal;
}

.contactworkerarea-text {
  padding-right: 5px;
  font-style: normal;
}

.contactworkerarea-text:last-child {
  margin-bottom: 5px;
  font-style: normal;
}


.bold-text {
  font-weight: 700;
}

.contact-worker-card-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.contact-card-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}


.c-contact-worker__sticky-top {
	z-index:10;
	background-color:#fafafa;

	@media (--sm) {
		position:-webkit-sticky;
		position:sticky;
		top:0;
	}
}

.c-contact-worker__change-lang-container {
	display:flex;

}

.close-contact {
	display: flex;
	border:none;
	background-color:transparent;
	cursor:pointer;
  width: 100%;
  height: 100%;

	@media (--sm) {
		position:static;
	}
}

.c-contact-worker__item {
	display:flex;
	flex-direction:column;
	align-items:center;
}
.c-contact-worker__item-row {
	display:flex;
	justify-content:space-between;
	width:100%;
}

.c-contact-numberofcoworkers {
  font-size: var(--type-sz-60);
  line-height: var(--type-lh-60);
  font-family: var(--type-ff-header);
  padding-top: 20px;
  margin-right: 20px;
}

.c-contactworkerlist-container {
  background: linear-gradient(to bottom, var(--pearl-50) 42px, var(--pearl-15) 0%); 
  padding-top: 1px;
}

@media (max-width: 767px) {
  .c-contactworkerlist-container {
    background: var(--pearl-15);
  }
}