/* =============================================================================
 * List
 *
 * postcss-bem-linter: define list
============================================================================= */
.c-list {
	background-color:var(--block-bg);
	color:var(--text);
}
.c-list--transparent {
	background-color:transparent;
}
.c-list__list {
	margin:0 auto;
	padding:0;
	list-style:none;
}
.c-list__item {
	margin-bottom:40px;
}
.c-list--divider .c-list__item:not(:last-of-type) {
	padding-bottom:40px;
	border-bottom:1px solid var(--pearl-25);
}
.c-list--compact .c-list__item[class] {
	margin-bottom:20px;
	padding-bottom:20px;
}
.c-list--inline .c-list__list {
	display:flex;
	flex-wrap:wrap;
}
.c-list--inline .c-list__item {
	margin:0 10px 14px 0;
}
