/* =============================================================================
 * Logo
 *
 * 1. Remove margins if an h1 element is used.
 * 2. For text fallback if the image doesn’t load. Not displayed in all
 *    browsers.
 *
 * postcss-bem-linter: define logo
============================================================================= */
.c-logo {
	width:160px;
	margin:0; /* 1 */
	padding:0;
	color:var(--gray-1); /* 2 */
	font-size:var(--type-sz-120);
	line-height:var(--type-lh-120);
	font-family:var(--type-ff-header);

	@media(--md) {
		width:200px;
	}
}
.c-logo img {
	width:160px;
	height:100%;

	@media (--md) {
		width:200px;
	}
}
.c-logo a {
	display:flex;
	color:inherit; /* 2 */
	text-decoration:none;
}
