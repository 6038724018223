/* =============================================================================
 * Teaser
 * postcss-bem-linter: define teaser
============================================================================= */
.c-teaser {
	position:relative;
	--bg:var(--white);
	--text-accent:var(--gray-2);
	background-color:var(--bg);
}
.c-teaser__link {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:2;
}
.c-teaser__media {
	width:100%;
}
.c-teaser__media img {
	width:100%;
	margin-bottom:16px;

}
.c-teaser__category {
	margin-bottom:10px;
	color:var(--text-accent);
	font-weight:bold;
	font-size:var(--type-sz-10);
	line-height:var(--type-lh-10);
}
.c-teaser__preamble {
	margin-bottom:10px;
	color:var(--text-accent);
	font-style:italic;
	font-size:var(--type-sz-10);
	line-height:var(--type-lh-10);
}
.c-teaser__title {
	margin-bottom:10px;
	font-size:var(--type-sz-60);
	line-height:var(--type-lh-60);
	font-family:var(--type-ff-header);
}
.c-teaser__byline {
	margin-bottom:10px;
	font-style:italic;
	font-size:var(--type-sz-10);
	line-height:var(--type-lh-10);
}
.c-teaser__kicker {
	font-size:var(--type-sz-20);
	line-height:var(--type-lh-20);
	font-family:var(--type-ff-header);
}
.c-teaser__kicker:last-child {
	margin-bottom:0;
}
.c-teaser__text p {
	margin-bottom:10px;
}
.c-teaser__footer {
	margin-top:auto;
}
.c-teaser--fuscous {
	--bg:var(--fuscous-15);
	--text-accent:var(--fuscous);
}
.c-teaser--hippie {
	--bg:var(--hippie-15);
	--text-accent:var(--hippie);
}
.c-teaser--calypso {
	--bg:var(--calypso-15);
	--text-accent:var(--calypso);
}
.c-teaser--pearl {
	--bg:var(--pearl-15);
	--text-accent:var(--fuscous);
}

/* Floating */
.c-teaser--floating .c-teaser__content {
	position:relative;
	width:70%;
	margin:-90px auto 0 auto;
	padding:26px 10px;
	background-color:var(--gray-5);
	text-align:center;
}

/* Solid */
.c-teaser--solid {
	padding:32px 24px;
	background-color:var(--white);
	color:var(--gray-1);
}
.c-teaser--solid .c-teaser__content {
	display:flex;
	flex-direction:column;
	height:100%;
}
.c-teaser--solid .c-teaser__footer {
	padding-top:32px;
	font-size:var(--type-sz-10);
	line-height:var(--type-lh-10);
}
.c-teaser--solid .c-teaser__footer a {
	color:var(--calypso);
}
.c-teaser--solid .c-teaser__title {
	margin-bottom:10px;
	font-size:var(--type-sz-50);
	line-height:var(--type-lh-50);
}

/* Overlap */
.c-teaser--overlap {
	display:flex;
	align-items:center;
	flex-flow:column;
}
.c-teaser--overlap .c-teaser__content {
	display:flex;
	position:relative;
	align-items:center;
	flex:1;
	margin:0 auto;
	padding:0 20px 24px;
	text-align:center;
	flex-flow:column;
}
.c-teaser--overlap .c-teaser__content::before {
	position:absolute;
	top:-18px;
	left:7%;
	width:86%;
	height:18px;
	background-color:var(--bg);
	content:"";
}
.c-teaser--overlap .c-teaser__media img {
	margin-bottom:0;
}
.c-teaser--overlap .c-teaser__category {
	max-width:86%;
	margin-right:auto;
	margin-left:auto;
}
.c-teaser--overlap .c-teaser__title {
	max-width:86%;
	margin-right:auto;
	margin-bottom:12px;
	margin-left:auto;
	font-size:var(--type-sz-50);
	line-height:var(--type-lh-50);
}
.c-teaser--overlap .c-teaser__footer {
	margin-top:auto;
	padding-top:24px;
	color:var(--text-accent);
	font-size:var(--type-sz-10);
	line-height:var(--type-lh-10);
}

/* Lazy loading images */
.c-teaser__media img[lazy="loaded"] {
	opacity:0;
	animation-name:fadein;
	animation-duration:0.5s;
	animation-timing-function:ease-out;
	animation-iteration-count:1;
	animation-direction:normal;
	animation-fill-mode:forwards;

	@keyframes fadein {
		0% {
			opacity:0;
		}
		100% {
			opacity:1;
		}
	}
}
