/* =============================================================================
 * Filter dropdown
 * postcss-bem-linter: define filter dropdown
============================================================================= */
.c-office-filter-dropdown {
	position:relative;
	flex-grow:100;
	min-width:116px;
	max-width:330px;
	width:100%;
	padding-bottom: 10px;
}
.c-office-filter-dropdown__button {
	display:flex;
	position:relative;
	justify-content:space-between;
	align-items:center;
	width:100%;
	padding:10px 20px;
	border:1px solid #e3ddd7;
	border-radius:4px 4px 0 0;
	background-color:var(--white);
	color:#666;
	cursor:pointer;
	min-height: 40px;
}
.c-office-filter-dropdown__button:hover {
	background-color:var(--gray-5);
}
.c-office-filter-dropdown__button--active {
	border-color:var(--hippie);
	background-color:#eef3f7;

	@media (--sm) {
		border-bottom:none;
	}
}
.c-office-filter-dropdown__button-text {
	margin-right:5px;
}
.c-office-filter-dropdown__button--border-bottom-none {
	border-bottom:none;
}
.c-office-filter-dropdown__button-tag {
	position:absolute;
	top:-16px;
	left:10px;
	padding:2px 8px;
	border-radius:5px;
	background-color:var(--white);
	font-size:12px;
}
.c-office-filter-dropdown__toggle-icon {
	fill:#666;
}
.c-office-filter-dropdown__list {
	display:none;
	position:absolute;
	z-index:10;
	max-height:80vh;
	width:100%;
	overflow-y:auto;
	border:1px solid var(--hippie);
	border-top:none;
	border-radius:0 0 4px 4px;
	list-style:none;
	background-color:var(--white);

	@media (--sm) {
		display:block;
		padding:0 20px;
	}
}

.c-mobile-dropdown__list {
	display:none;
	position:absolute;
	z-index:10;
	max-height:80vh;
	width:100%;
	overflow-y:auto;
	border:1px solid var(--hippie);
	border-top:none;
	border-radius:0 0 4px 4px;
	list-style:none;
	background-color:var(--white);

	@media (--sm) {
		display:block;
		padding:0 20px;
	}
}

.c-office-filter-dropdown__city {
	position:relative;
	margin-bottom:24px;
	padding:16px 0;
}
.c-office-filter-dropdown__city::after {
	position:absolute;
	bottom:0;
	left:0;
	width:44px;
	height:1px;
	background:var(--black);
	content:"";
}
.c-office-filter-dropdown__region {
	padding:12px;
}
.c-office-filter-dropdown__region input,
.c-office-filter-dropdown__city input {
	margin-right:16px;
	border:1px solid #2f688a;
}


.c-office-filter__show-filters-banner {
	position: relative;
	background-color: #f0f0f0;
	padding: 10px;
  }

  .filter-local-button {
	background-color: #2B5773;
	color: white;
	border: none;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
	border-radius: 5px;
	width: 100%;
	text-align: center;
  }

  .filter-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: end;
  }

	.overlay-content {
		background-color: white;
		padding: 20px;
		width: 100%;
		max-width: 400px;
		border-radius: 8px;
		text-align: center;
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
		max-height: 100%;
	  }
  
  .filter-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 20px;
	margin-right: 20px;
  }

  .filter-header h2 {
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
	font-family: "AvenirRegular", Arial, sans-serif;
	margin-bottom: 24px;
  }

  .close-filter-container {
	margin-bottom: 24px;
  }

  .filter-title {
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	font-weight: bold;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	padding: 16px 24px;
  }

  .filter-content {
	padding-left: 20px;
	padding-right: 20px;
  }
  
  .filter-content ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
  }
  
  .filter-content li {
	margin: 5px 0;
  }
  
  .selected-tags {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
  }
  
  .selected-tag {
	display: flex;
	background-color: #f0f0f0;
	padding: 5px 10px;
	margin: 5px;
	border-radius: 20px;
	height: 28px;
  }
  
  .selected-tag button {
	margin-right: 6px;
    padding: 0;
    height: 16px;
	width: 16px;
	background: none;
	border: none;
	cursor: pointer;
  }

  .selected-tag span {
	width: 100%;
	font-size: 14px;
    font-weight: 300;
    font-family: "AvenirRegular", Arial, sans-serif;
    line-height: 16px;
  }

  .result-button {
	width: 100%;
	padding: 15px;
	background-color: #2B5773;
	color: white;
	border: none;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	margin-top: 20px;
  }

  .c-office-filter-mobile-dropdown {
  	list-style-type: none;
  	padding: 0;
  	margin: 0;
  	max-height: 200px;
  	overflow-y: auto;
  	padding-right: 10px;

  	.c-office-filter__place {
  		padding: 12px 0;
  		display: flex;
  	}

  	.c-office-filter__city {
  		padding: 12px 0;
  		display: flex;
  	}

	.c-office-filter__region {
		padding: 12px 0;
  		display: flex;
	}
  }

  .c-office-filter-mobile-dropdown::-webkit-scrollbar {
	width: 8px;
  }
  
  .c-office-filter-mobile-dropdown::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 4px;
  }

  .open-overlay-btn {
	padding: 10px 20px;
	background-color: #2f5d77;
	color: white;
	border: none;
	cursor: pointer;
  }
  
  .content {
	padding: 20px;
  }
  
  .close-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: 20px;
	cursor: pointer;
  }

  .cta-btn {
	margin-top: 20px;
	background-color: #2f5d77;
	color: white;
	padding: 10px;
	width: 100%;
	border: none;
	cursor: pointer;
  }

  .dropdown {
	margin: 10px 0;
	padding: 10px;
	background-color: #f0f0f0;
	border-radius: 5px;
  }