/* =============================================================================
 * Filter
 * postcss-bem-linter: define filter
============================================================================= */
.c-filter__sticky-wrapper {
	background-color:var(--pearl-50);
	height: 60px;

	@media (--sm) {
		top:0px;
	}
}

@media (max-width: 767px) {
	.c-filter__sticky-wrapper {
		background-color:var(--pearl-15);
	}
  }

.c-filter__mobile {
	position:fixed;
	top:68px;
	right:0;
	left:0;
	bottom: 0;
	z-index:100;
	background-color:var(--white);
}
.c-filter__show-filters-banner {
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	row-gap: 20px;
	background-color: var(--pearl-50);
}

@media (max-width: 767px) {
	.c-filter__show-filters-banner  {
		background-color:var(--pearl-15);
	}
  }


.mobile-search-bar input {
	padding-top: 20px;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 20px;
	outline: none;
	width: 100%;
}

.c-filter-button {
	display:flex;
	align-items:center;
	justify-content: center;
	margin:5px;
	width: 45%;
}

.filter-results-container {
	display: flex;
	margin-top: 15px;
	margin-bottom: 15px;
	align-items: center;
	justify-content: space-between;
	column-gap: 20px;
	width: 85%;
  }

.filter-button {
	background-color: var(--hippie);
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding-left: 20px;
    padding-top: 8px;
	border-radius: 3px;
	width: 120px;
	height: 40px;
}

.filter-button i {
	margin-left: 8px;
  }

  .results-indicator {
	margin-left: 16px;
	margin-right: 16px;
  }

.c-filter__show-filters-button {
	padding:18px 0;
	border:none;
	background-color:transparent;
	font-size:16px;
	margin-top: 8px;
}
.c-filter__show-filters-button .c-icon {
	margin-left:12px;
	margin-bottom: 4px;
}
.c-filter__content-wrapper {
	background-color:var(--gray-5);
}
.c-filter__dropdown-wrapper {
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
	align-items:center;
	gap:20px;
	padding-top: 11px;
	padding-bottom: 20px;
	background-color:var(--gray-5);
}

@media (max-width: 767px) {
	.c-filter__dropdown-wrapper {
		padding-top: 20px;
		padding-bottom: 0px;
	}
  }

.c-filter__sorting-container {
	display:flex;
	position:relative;
	justify-content:space-between;
	padding:12px 40px;
	box-shadow:0 4px 10px rgba(227, 221, 215, 0.15);

	@media (--sm) {
		background-color:var(--gray-5);
	}
}
.c-filter__select-label {
	color:#666;
}
.c-filter__sorting-select {
	border:none;
	background-color:var(--white);
	font-weight:400;
	cursor:pointer;
}
.c-filter__sorting-select:hover {
	text-decoration:underline;
}
.c-filter__sorting-options-dropdown {
	position:absolute;
	z-index:5;
	min-width:250px;
	padding:12px 0;
	border:1px solid var(--hippie);
	border-radius:5px;
	background-color:var(--white);
}
.c-filter__sorting-option {
	padding:12px 42px;
	list-style:none;
	cursor:pointer;
}
.c-filter__sorting-option--active {
	margin-left:-26px;
}
.c-filter__check-icon {
	margin-right:6px;
}
.c-filter__sorting-reset {
	border:none;
	background-color:var(--white);
	color:#2f688a;
	font-weight:900;

	@media (--sm) {
		background-color:var(--gray-5);
	}
}
.c-filter__sorting-reset:hover {
	text-decoration:underline;
}
.c-filter__hits {
	flex-grow:100;
	min-width:116px;
	font-size:24px;
	text-align:center;
}
.c-filter__hits-mobile {
	border-left:1px solid var(--black);
}
.c-filter__hits-heading {
	margin-bottom:0;
	text-align:right;
}
.c-filter__hits-number {
	color:var(--hippie);
}
.c-filter__choices-container {
	/* margin-top: 20px;
	overflow:scroll;
	display:none; */
	@media (--sm) {
		display:none;
		height:calc(90vh - 200px);
		overflow:scroll;
	}
}
.c-filter__list {
	display: flex;
    flex-direction: column;
    overflow: auto;
    z-index: 10;
    border-top: none;
    background-color: white;
    width: 100%;
    padding-left: 30px;
    margin-top: 10px;
    padding-top: 8px;
	list-style: none;
	height: 61vh;
	padding-bottom: 20px;
}

.c-filter__city {
	position:relative;
	margin-bottom:24px;
	padding:16px 0;
}
.c-filter__city::after {
	position:absolute;
	bottom:0;
	left:0;
	width:44px;
	height:1px;
	background:var(--black);
	content:"";
}

.c-area-filter {
	margin-left: 35px;
	padding-top: 15px;
}

.c-filter__region {
	padding:12px;
}
input[type="checkbox"] {
	width:14px;
	height:14px;
	margin-right:16px;
	border:1px solid #2f688a;
}
.c-filter__place-wrapper {
	border-bottom:1px solid var(--black);
}
.c-filter__place-wrapper:last-child {
	@media (--sm) {
		border-bottom:none;
	}
}
.c-filter__place {
	position:relative;
	padding:16px 0;
}

/* .c-filter__size-input-block {
} */
.c-filter__size-input-heading {
	margin:40px 0 76px 12px;
	font-size:16px;

	@media (--sm) {
		margin:40px 0 38px 12px;
	}
}
.c-filter__size-input-wrapper {
	display:flex;
	flex-direction:row;
	align-items:center;
	gap:6px;

	@media (--sm) {
		flex-direction:column;
		margin-bottom:50px;
	}
}
.c-filter__size-input-container {
	position:relative;
}
.c-filter__size-input-tag {
	position:absolute;
	top:-16px;
	left:10px;
	padding:2px 8px;
	border-radius:5px;
	background-color:var(--white);
	font-size:12px;
}
.c-filter__size-input {
	padding:10px 34px 10px 20px !important;
	border:1px solid #666 !important;
	border-radius:4px 4px 0 0;
	background-color:var(--white);
	color:#666;
}
.c-filter__size-input-m2-tag {
	position:absolute;
	top:12px;
	right:8px;
}
.c-filter__button-container {
	display:flex;
	position:fixed;
	bottom:0;
	justify-content:center;
	gap:16px;
	width:100%;
	padding:20px 0;
	box-shadow:0 -5px 10px rgba(227, 221, 215, 0.15);
	background-color:var(--white);

	@media (--sm) {
		display:none;
	}
}
.c-filter__button-container button {
	min-width:120px;
	max-width:170px;
	width:30%;
	padding:12px 24px;
	border:none;
	border-radius:3px;
}
.c-filter__button-cancel {
	background-color:var(--pearl-50);
}
.c-filter__button-save {
	background-color:var(--hippie);
	color:var(--white);
}

.vertical-line {
	border-left: 1px solid #666666;
	height: 35px;
	width: 30px;
}

.c-filter {
	background-color:var(--gray-5);
}
