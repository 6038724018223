.c-contact-form {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: var(--hippie);
  color: #fff;
}
.c-contact-form__input-wrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.c-contact-form__button-wrapper {
  display: flex;
  justify-content: end;
}

.c-contact-us-form .contact-background {
  background: #31698b;
}

.c-contact-us-form .contact-form #contactFormSendButton {
  background: #5c8fb4;
  font-weight: bold;
}

@media (min-width: 767px) {
  .c-contact-us-form .contact-form #contactFormSendButton {
    max-width: 200px;
  }
}

.c-contact-us-form .contact-form-submit-btn-wrapper {
  float: right;
}
