/* =============================================================================
 * Media gallery
 *
 * postcss-bem-linter: define media-gallery
============================================================================= */
.c-media-gallery__preview {
	--gap:24px;

	@media (--md) {
		display:inline-flex;
		flex-wrap:wrap;
		width:calc(100% + var(--gap));
		margin:calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
	}
}
.c-media-gallery__preview-media {
	position:relative;
	margin:24px 20px 0 20px;

	@media (--md) {
		flex:1 0;
		margin:var(--gap) 0 0 var(--gap);
	}
}
.c-media-gallery__preview-media img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.c-media-gallery__reveal {
	display:flex;
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	width:100%;
	margin:0;
	border:none;
	background-color:transparent;
	color:#fff;
	font-size:var(--type-sz-60);
	line-height:var(--type-lh-60);
	font-family:var(--type-ff-header);
	text-decoration:none;
	cursor:pointer;
}
.c-media-gallery__reveal span {
	position:relative;
	z-index:2;
}
.c-media-gallery__reveal::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:1;
	background-color:#000;
	content:"";
	opacity:0.5;
	transition:opacity 0.2s ease;
}
.c-media-gallery__reveal:hover::before {
	background-color:#000;
	opacity:0.8;
	transition:opacity 0.2s ease;
}
.c-media-gallery__gallery {
	display:none;
	scroll-margin-top:80px;
}
.c-media-gallery__gallery-media {
	margin-top:24px;
	scroll-margin-top:140px;
}
.c-media-gallery__gallery-media img {
	width:100%;
}
.c-media-gallery__blueprint {
	position:relative;
	width:100%;
	padding-top:56.25%;
}
.c-media-gallery__blueprint a {
	align-self:baseline;
}
.c-media-gallery__blueprint > div {
	display:flex;
	position:absolute;
	top:0;
	left:0;
	justify-content:center;
	width:100%;
	height:100%;
	padding:0 24px;
	flex-flow:column;
}
.c-media-gallery__blueprint img {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:-1;
	width:100%;
	height:100%;
	object-fit:cover;
	opacity:0.15;
}
.c-media-gallery__blueprint h3 {
	margin:0 0 10px;

	@media (--lg) {
		margin:0 0 20px;
		font-size:var(--type-sz-60);
		line-height:var(--type-lh-60);
	}
}
.c-media-gallery__blueprint p {
	display:block;
	margin:0 0 10px;
	font-size:var(--type-sz-10);
	line-height:var(--type-lh-5);

	@media (--lg) {
		margin:0 0 20px;
	}
}
.c-media-gallery__blueprint button {
	align-self:baseline;
}
.c-media-gallery__footer {
	display:flex;
	justify-content:right;
	width:100%;
	margin-top:36px;
	padding-right:25px;
}
