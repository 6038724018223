/* =============================================================================
 * Embed
 *
 * postcss-bem-linter: define embed
============================================================================= */
.c-embed {
	position:relative;
	width:100%;
	height:auto;
}
.c-embed__target {
	position:relative;
	overflow:hidden;
	padding-top:56.25%;
}
.c-embed__target iframe {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
.c-embed__poster {
	width:100%;
}
.c-embed__poster img {
	max-width:none;
	width:100%;
	height:100%;
	object-fit:cover;
}
.c-embed__poster {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	width:100%;
	height:100%;
}
.c-embed__play {
	display:flex;
	position:absolute;
	top:0;
	left:0;
	justify-content:center;
	align-items:center;
	width:100%;
	height:100%;
	padding:0;
	border:none;
	background:transparent;
	cursor:pointer;
}
.c-embed__play-symbol {
	display:flex;
	top:50%;
	left:50%;
	justify-content:center;
	align-items:center;
	width:60px;
	height:60px;
	border-radius:50%;
	box-shadow:0 4px 10px 0 rgba(0, 0, 0, 0.3);
	background-color:var(--control-bg);
}
.c-embed__play-symbol svg {
	fill:var(--control-text);
}


