/* =============================================================================
 * Filters wrapper
 * postcss-bem-linter: define filters-wrapper
============================================================================= */
.c-filters-wrapper {
	padding-top:30px;
	padding-bottom:30px;
	border-bottom:1px solid var(--pearl-50);
	background-color:var(--pearl-50);
}

@media (max-width: 767px) {
	.c-filters-wrapper {
		background-color:var(--pearl-15);
	}
  }

.c-filters-wrapper--slim {
	padding-top:20px;
	padding-bottom:20px;
}
.c-filters-wrapper__inner {
	display:flex;
	position:relative;
	justify-content:space-between;
	flex-wrap:wrap;
}
.c-filters-wrapper__form {
	display:flex;
	flex-wrap:wrap;
	align-items:center;
	width:100%;
	margin:0;
}
.c-filters-wrapper__form > *:not(label) {
	margin-right:3%;
	margin-bottom:20px;

	@media (--sm) {
		margin-bottom:0;
	}
}
.c-filters-wrapper__form > *:last-child {
	margin-right:0;
}
.c-filters-wrapper__form-item--lg {
	width:50%;
}
.c-filters-wrapper__form .c-custom-checkbox__label {
	margin-left:32px;
}
.c-filters-wrapper .c-multi-select {
	width:100%;
	margin-top:8px;

	@media (--xs) {
		width:48%;
		margin:0;
	}

	@media (--md) {
		width:22%;
	}
}
.c-filters-wrapper .c-range-slider {
	width:100%;

	@media (--md) {
		width:50%;
	}
}
.c-filters-wrapper__reset {
	position:absolute;
	top:-14px;
	right:30px;

	@media (--md) {
		right:80px;
	}
}
.c-filters-wrapper__list {
	display:flex;
	flex-wrap:wrap;
	align-items:center;
	width:100%;
	margin:0;
	padding:0;
	list-style:none;
}
.c-filters-wrapper--space-between {
	@media (--md) {
		justify-content:space-between;
	}
}
.c-filters-wrapper__list li {
	margin:3px 5px;
}
