/* =============================================================================
 * Location
 *
 * postcss-bem-linter: define location
============================================================================= */
.c-location {
	background-color:var(--block-bg);
	color:var(--text);
	text-align:center;
}
.c-location__text {
	font-size:var(--type-sz-20);
	line-height:var(--type-lh-20);
}
.c-location__map {
	padding-bottom:20px;
	max-width: 1130px;
	max-height: 611px;
	width: 90%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
}
.c-location__pois {
	display:flex;
	justify-content:center;
	flex-flow:wrap row;
}
.c-location__poi {
	flex:0 0 auto;
	max-width:150px;
	padding:0 15px;
}
.c-location__poi svg {
	margin-bottom:20px;
	fill:var(--icon-subtle);
}
