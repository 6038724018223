/* =============================================================================
 * Hero
 * postcss-bem-linter: define hero
============================================================================= */
.c-hero {
  position: relative;
  padding: 20px 0;
  background: var(--block-bg);
  color: var(--text);

  @media (--md) {
    padding: 60px 0 20px;
  }
}
.c-hero--pearl {
  background-color: var(--pearl);
  color: var(--gray-1);
}
.c-hero--white {
  background-color: var(--white);
}
.c-hero--dark {
  background-color: var(--gray-1);
}
.c-hero--white .c-hero__background-image,
.c-hero--dark .c-hero__background-image {
  opacity: 0.5;
}
.c-hero--calypso {
  background-color: var(--calypso);
}
.c-hero--compact {
  height: 80px;

  @media (--sm) {
    height: 120px;
  }

  @media (--md) {
    height: 240px;
  }
}
.c-hero__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.c-hero__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
.c-hero__background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.15;
}
.c-hero__image {
  z-index: 1;
  order: 1;
  margin: 0 0 -50px 0;

  @media (--sm) {
    order: unset;
    width: 50%;
    margin-bottom: 0;
  }

  @media (--md) {
    margin-bottom: -40px;
  }
}
.c-hero__image img {
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.07);
}
.c-hero__content {
  z-index: 1;

  @media (--sm) {
    width: 65%;
  }
}
.c-hero__content--align-right {
  @media (--sm) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
    margin-top: 50px;
    margin-left: auto;
    padding-left: 50px;
  }
}
.c-hero__content--align-center {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  flex-flow: column;
}
.c-hero__content--align-center .c-hero__chips ul {
  justify-content: center;
}
.c-hero__title {
  margin-bottom: 15px;
  word-break: break-word;
}
.c-hero__title--small {
  font-size: var(--type-sz-80);
  line-height: var(--type-lh-80);
  font-family: var(--type-ff-header);
}
.c-hero__chips {
  margin-top: 36px;
}
.c-hero__byline {
  font-size: var(--type-sz-50);
  line-height: var(--type-lh-50);
  font-family: var(--type-ff-header);
}
.c-hero__text p {
  margin-bottom: 10px;
  font-size: var(--type-sz-20);
  line-height: var(--type-lh-20);

  @media (--md) {
    margin-bottom: 15px;
  }
}
.c-hero__text a {
  color: inherit;
}
.c-hero__info {
  font-size: var(--type-sz-10);
  line-height: var(--type-lh-10);
}
.c-hero__actions {
  width: 100%;
  margin: 16px 0 10px;
}
.c-hero__actions--align-right {
  text-align: center;

  @media (--sm) {
    text-align: right;
  }
}
.c-hero__button[class] {
  background-color: var(--hero-control-bg);
  color: var(--white);
}
.c-hero__button--light {
  background-color: var(--hippie);
  color: var(--white);
}
.c-hero__icon[class] {
  margin-right: 6px;
  fill: var(--text);
}
.c-hero .c-contact-person--offset-extended .c-contact-person__inner {
  margin-top: 0;

  @media (--lg) {
    padding: 100px 60px 40px 100px;
  }
}
.c-contact-hero-wrapper .c-button {
  background-color: #5c8fb4 !important;
  color: var(--white) !important;
}
.c-contact-hero-wrapper .c-button:hover {
  text-decoration: none;
}
.c-contact-hero-wrapper .c-button:visited {
  background-color: #5c8fb4;
  color: var(--white);
}
