/* =============================================================================
 * Person
 *
 * postcss-bem-linter: define person
============================================================================= */
.c-person {
	position:relative;
	max-width:320px;
}
.c-person--small {
	max-width:140px;
}
.c-person__image {
	margin-bottom:12px;
}
.c-person__content {
	padding:0 12px;
}
.c-person--small .c-person__content {
	padding:0;
	text-align:center;
}
.c-person__name {
	margin-bottom:2px;
	text-align:center;
}
.c-person--small .c-person__name {
	font-size:var(--type-sz-40);
}
.c-person__role {
	margin-bottom:24px;
	text-align:center;
}
.c-person--small .c-person__role {
	margin-bottom:12px;
}
