/* =============================================================================
 * Gallery
 * postcss-bem-linter: define gallery
============================================================================= */
.c-gallery {
	display:flex;
	flex-direction:column;
}
.c-gallery img {
	border-radius:5px;
	box-shadow:0 4px 10px 0 rgba(0, 0, 0, 0.07);
}
.c-gallery__main-image {
	flex:1;
	margin:auto;
}
.c-gallery__main-image img {
	height:auto;
}
