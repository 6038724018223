/* =============================================================================
 * Accordion
 *
 * postcss-bem-linter: define accordion
============================================================================= */
.c-accordion {
	padding:0 30px;
	border-bottom:1px solid var(--black);
}
.c-accordion[aria-expanded="true"] {
	border-bottom:1px solid var(--black);
	background-color:rgba(255, 255, 255, 0.1);
}
.c-accordion__trigger {
	display:flex;
	align-items:center;
	width:100%;
	padding:24px 0;
	border:none;
	background-color:transparent;
	color:var(--text);
	font-weight:600;
	font-size:var(--type-sz-default);
	line-height:var(--type-lh-default);
	font-family:var(--type-ff-default);
	cursor:pointer;

	@media(--sm) {
		min-height:90px;
	}
}
.c-accordion--compact .c-accordion__trigger {
	padding:16px 0;
}
.c-accordion__trigger:hover {
	text-decoration:underline;
}
.c-accordion__header {
	margin:0;
}
.c-accordion__icon {
	margin-right:24px;
	margin-left:2px;
}
.c-accordion__icon--90 {
	transform:rotate(90deg);
}
.c-accordion__trigger svg {
	transition:0.2s ease transform;
	transform:rotate(270deg);
}
.c-accordion__trigger[aria-expanded="true"] svg {
	transition:0.2s ease transform;
	transform:rotate(90deg);
}
.c-accordion__content {
	margin-left:42px;
	padding-bottom:24px;
}
.c-accordion__title {
	font-size:var(--type-sz-40);
	font-family:var(--type-ff-header);
	text-align:left;
}
