/* =============================================================================
 * Width limiter
 * Limits width and centers container on page.
 * postcss-bem-linter: define width-limiter
============================================================================= */
.o-width-limiter[class] {
	max-width:var(--limiter-default);
	margin-right:auto;
	margin-left:auto;
	padding-right:20px;
	padding-left:20px;
}
.o-width-limiter--narrow[class] {
	max-width:var(--limiter-narrow);
	margin-right:auto;
	margin-left:auto;
	padding-right:20px;
	padding-left:20px;
}
.o-width-limiter--slim[class] {
	max-width:var(--limiter-slim);
	margin-right:auto;
	margin-left:auto;
	padding-right:20px;
	padding-left:20px;
}
.o-width-limiter--full[class] {
	max-width:var(--limiter-full);
	margin:0 auto;
	padding-right:20px;
	padding-left:20px;

	@media (--md) {
		padding-right:80px;
		padding-left:80px;
	}
}
