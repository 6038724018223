/* =============================================================================
 * Filter
 * postcss-bem-linter: define filter
============================================================================= */
.c-office-filter__sticky-wrapper {
	background-color: #F9F9F9;
	height: 100%;

	@media (--sm) {
		top: 0px;
	}
}

.c-office-filter__wrapper {
	display: flex;
	flex-direction: row;
}

@media (max-width: 767px) {
	.c-office-filter__sticky-wrapper {
		background-color: var(--pearl-15);
	}
}

.c-office-filter__mobile {
	position: fixed;
	top: 68px;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 100;
	background-color: var(--white);
}

.c-office-filter__show-filters-banner {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	row-gap: 20px;
	background-color: var(--pearl-50);
}

@media (max-width: 767px) {
	.c-office-filter__show-filters-banner {
		background-color: var(--pearl-15);
	}
}


.mobile-search-bar input {
	padding-top: 20px;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 20px;
	outline: none;
	width: 100%;
}

.c-office-filter-button {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px;
	width: 45%;
}

.filter-results-container {
	display: flex;
	margin-top: 15px;
	margin-bottom: 15px;
	align-items: center;
	justify-content: space-between;
	column-gap: 20px;
	width: 85%;
}

.filter-button {
	background-color: var(--hippie);
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding-left: 20px;
	padding-top: 8px;
	border-radius: 3px;
	width: 120px;
	height: 40px;
}

.filter-button i {
	margin-left: 8px;
}

.results-indicator {
	margin-left: 16px;
	margin-right: 16px;
}

.c-office-filter__show-filters-button {
	padding: 18px 0;
	border: none;
	background-color: transparent;
	font-size: 16px;
	margin-top: 8px;
}

.c-office-filter__show-filters-button .c-icon {
	margin-left: 12px;
	margin-bottom: 4px;
}

.c-office-filter__content-wrapper {
	height: 560px;
	width: 35%;
	background-color: var(--gray-5);
	text-align: left;
	padding-top: 40px;
	padding-right: 30px;
}

.c-office-filter__dropdown-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: start;
	gap: 20px;
	padding-top: 11px;
	padding-bottom: 20px;
	background-color: var(--gray-5);
}

@media (max-width: 767px) {
	.c-office-filter__dropdown-wrapper {
		padding-top: 20px;
		padding-bottom: 0px;
	}
}

.c-office-filter__sorting-container {
	display: flex;
	position: relative;
	justify-content: space-between;
	background-color: white;
}

.c-office-filter__select-label {
	color: #666;
}

.c-office-filter__sorting-select {
	border: none;
	background-color: var(--white);
	font-weight: 400;
	cursor: pointer;

	/* @media (--sm) {
		background-color:var(--gray-5);
	} */
}

.c-office-filter__sorting-select:hover {
	text-decoration: underline;
}

.c-office-filter__sorting-options-dropdown {
	position: absolute;
	z-index: 5;
	min-width: 250px;
	padding: 12px 0;
	border: 1px solid var(--hippie);
	border-radius: 5px;
	background-color: var(--white);
	top: 50px;
}

.c-office-filter__sorting-option {
	padding: 12px 42px;
	list-style: none;
	cursor: pointer;
}

.c-office-filter__sorting-option--active {
	margin-left: -26px;
}

.c-office-filter__check-icon {
	margin-right: 6px;
}

.c-office-filter__sorting-reset {
	border: none;
	background-color: var(--white);
	color: #2f688a;
	font-weight: 900;

	@media (--sm) {
		background-color: var(--gray-5);
	}
}

.c-office-filter__sorting-reset:hover {
	text-decoration: underline;
}

.c-office-filter__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	width: 100%;
}

.c-office-filter__left-item {
	display: flex;
	justify-content: left;
	align-items: left;
}

.c-office-filter__right-item {
	display: flex;
	justify-content: right;
	align-items: right;
}

.c-office-filter__hits {
	flex-grow: 100;
	min-width: 116px;
	font-size: 24px;
	text-align: center;
}

.c-office-filter__hits-mobile {
	border-left: 1px solid var(--black);
}

.c-office-filter__hits-heading {
	margin-bottom: 0;
	text-align: right;
}

.c-office-filter__hits-number {
	color: var(--hippie);
}

.c-office-filter__choices-container {

	/* margin-top: 20px;
	overflow:scroll;
	display:none; */
	@media (--sm) {
		display: none;
		height: calc(90vh - 200px);
		overflow: scroll;
	}
}

.c-office-filter__list {
	display: flex;
	flex-direction: column;
	overflow: auto;
	z-index: 10;
	border-top: none;
	background-color: white;
	width: 100%;
	padding-left: 30px;
	margin-top: 10px;
	padding-top: 8px;
	list-style: none;
	height: 61vh;
	padding-bottom: 20px;
}

.c-office-filter__city {
	position: relative;
	margin-bottom: 24px;
	padding: 16px 0;
}

.c-office-filter__city::after {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 44px;
	height: 1px;
	background: var(--black);
	content: "";
}

.c-area-filter {
	margin-left: 35px;
	padding-top: 15px;
}

.c-office-filter__region {
	padding: 12px;
}

input[type="checkbox"] {
	width: 14px;
	height: 14px;
	margin-right: 16px;
	border: 1px solid #2f688a;
}

.c-office-filter__place-wrapper {
	border-bottom: 1px solid var(--black);
}

.c-office-filter__place-wrapper:last-child {
	@media (--sm) {
		border-bottom: none;
	}
}

.c-office-filter__place {
	position: relative;
	padding: 16px 0;
}

/* .c-office-filter__size-input-block {
} */
.c-office-filter__size-input-heading {
	margin: 40px 0 76px 12px;
	font-size: 16px;

	@media (--sm) {
		margin: 40px 0 38px 12px;
	}
}

.c-office-filter__size-input-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 6px;

	@media (--sm) {
		flex-direction: column;
		margin-bottom: 50px;
	}
}

.c-office-filter__size-input-container {
	position: relative;
}

.c-office-filter__size-input-tag {
	position: absolute;
	top: -16px;
	left: 10px;
	padding: 2px 8px;
	border-radius: 5px;
	background-color: var(--white);
	font-size: 12px;
}

.c-office-filter__size-input {
	padding: 10px 34px 10px 20px !important;
	border: 1px solid #666 !important;
	border-radius: 4px 4px 0 0;
	background-color: var(--white);
	color: #666;
}

.c-office-filter__size-input-m2-tag {
	position: absolute;
	top: 12px;
	right: 8px;
}

.c-office-filter__button-container {
	display: flex;
	position: fixed;
	bottom: 0;
	justify-content: center;
	gap: 16px;
	width: 100%;
	padding: 20px 0;
	box-shadow: 0 -5px 10px rgba(227, 221, 215, 0.15);
	background-color: var(--white);

	@media (--sm) {
		display: none;
	}
}

.c-office-filter__button-container button {
	min-width: 120px;
	max-width: 170px;
	width: 30%;
	padding: 12px 24px;
	border: none;
	border-radius: 3px;
}

.c-office-filter__button-cancel {
	background-color: var(--pearl-50);
}

.c-office-filter__button-save {
	background-color: var(--hippie);
	color: var(--white);
}

.vertical-line {
	border-left: 1px solid #666666;
	height: 35px;
	width: 30px;
}

.c-office-filter {
	background-color: var(--gray-5);

	h3 {
		margin-left: 5%;
	}
}

.clear-choises-button {
	background-color: #86321D;
	color: white;
	border: none;
	padding: 10px 20px;
	font-size: 16px;
	border-radius: 10px;
	cursor: pointer;
	outline: none;
	display: inline-block;
	transition: background-color 0.3s ease;
	flex-grow: 100;
	min-width: 116px;
	max-width: 330px;
	width: 100%;
}

.clear-choises-button:hover {
	background-color: #6b271d;
}


.googlemapoffice {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#map {
	height: 560px;
	width: 100%;
}

#mobilemap {
	height: 440px;
	width: 100%;
}

.filter-find-local {
	display: flex;
	margin-top: 30px;
	margin-bottom: 0;
	align-items: center;
	justify-content: space-between;
	column-gap: 20px;
	width: 100%;
	height: 100px;
	background: white;
}

.filter-local-button {
	background-color: var(--hippie);
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	align-items: center;
	border-radius: 3px;
	width: 90%;
	height: 48px;
	margin: auto;
	justify-content: center;
}

#map-boxed {
	display: block;
	height: 560px;
	margin-bottom: 30px;
}

.custom-info-window {
	position: absolute;
	bottom: 10px;
	left: 10px;
	background: white;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 4px;
	display: none;

	z-index: 10;
	width: 300px;
	top: 255px;
	left: 10px;
	height: 165px;
	width: 420px;
	border-radius: 10px;
}

.custom-info-window img {
	width: 100px;
	height: 100px;
	margin-right: 10px;
}

.custom-info-window h3 {
	margin: 0;
	padding: 0;
	font-size: 16px;
}

.custom-info-window p {
	margin: 2px 0;
	font-size: 14px;
}

.info-window {
	display: flex;
	flex-direction: row;
}

.info-window img {
	width: 100px;
	height: 100px;
	margin-right: 10px;
}

.info-window-content {
	font-family: Arial, sans-serif;
	text-align: left;

	a {
		color: inherit;
		text-decoration: none;
	}
}

.info-window-content h3 {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-family: var(--type-ff-default);
	font-weight: 800;
	line-height: 22px;
}

.info-window-content p {
	margin: 2px 0;
	font-size: 14px;
	font-family: var(--type-ff-default);
	line-height: 22px;
	font-weight: 400;
}

.filters {
	margin-top: 10px;
}

.close-tag {
	display: flex;
	border: none;
	background-color: transparent;
	cursor: pointer;
	width: 100%;
	height: 100%;

	@media (--sm) {
		position: static;
	}
}

.show-all {
	display: inline-block;
	padding: 10px;
}

.show-all a {
	color: #007bff;
	text-decoration: none;
}

.show-all a:hover {
	text-decoration: underline;
}

.custom-marker {
	background-color: #FFFFFF;
	color: #2B5773;
	padding: 5px 10px;
	border-radius: 15px;
	font-family: var(--type-ff-default);
	font-size: 14px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
	white-space: nowrap;
}

.custom-marker span {
	display: inline-block;
}

.custom-cluster {
	cursor: default;
	transition: none;
	background-color: #2B5773;
	color: #ffffff;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: 500;
	box-shadow:
		0 0 0 3px rgba(43, 87, 115, 0.4),
		0 0 0 6px rgba(43, 87, 115, 0.3);
}



.info-window-container {
	display: flex;
	flex-direction: column;
	font-family: Arial, sans-serif;
	width: 300px;
	background: white;
	border-radius: 8px;
	overflow: hidden;
}

.info-window-image-wrapper {
	position: relative;
	width: 100%;
	height: 150px;
}

.info-window-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.info-window-details {
	text-align: left;
	padding: 15px;
}

@media (max-width: 767px) {
	.info-window-container {
		width: 210px;
	}

	.info-window-image-wrapper {
		height: 120px;
	}

	.info-window-details {
		padding: 8px;
	}
}

@media (max-width: 375px) {
	.info-window-container {
		width: 200px !important;
	}
}

.info-window-title {
	font-family: var(--type-ff-default);
	font-weight: 800;
	font-size: 16px;
	margin: 0;
	margin-left: 0 !important;
	color: #333333;
	line-height: 1.2;
}

.info-window-title a {
	color: #333333;
	text-decoration: none;
}

.info-window-title a:hover {
	text-decoration: underline;
}

.info-window-address,
.info-window-size {
	font-family: var(--type-ff-default);
	font-size: 14px;
	margin: 5px 0;
}

.info-window-premises {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	margin-top: 10px;
}

.c-object__icon-wrapper {
	background-color: #f5f5f5;
	padding: 5px 10px;
	border-radius: 3px;
	font-size: 12px;
	color: #333;
	border: 1px solid #ddd;
}

.info-window-carousel-controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	background: #f9f9f9;
}

.info-window-carousel-controls button {
	border: 2px solid #2B5773;
	background-color: transparent;
	color: #2B5773;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
	outline: none;
	transition: background-color 0.3s ease, color 0.3s ease;
}

.info-window__icon-wrapper {
	display: inline-block;
	margin-bottom: 4px;
	padding: 4px 8px;
	border-radius: 20%;
	background-color: var(--gray-4);
	line-height: 1;
}

.gm-ui-hover-effect {
	position: absolute !important;
	top: 10px !important;
	right: 10px !important;
	width: 30px !important;
	height: 30px !important;
	background: #333 !important;
	border-radius: 50% !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	cursor: pointer !important;
	z-index: 10 !important;
}

.gm-ui-hover-effect span {
	-webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E") !important;
	mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E") !important;
	background-color: white !important;
	width: 24px !important;
	height: 24px !important;
	display: block;
	margin: 0px !important;
}

@media (max-width: 768px) {
	.gm-style .gm-style-iw-c {
		max-width: 90vw !important;
		width: auto !important;
	}

	.gm-style .gm-style-iw-d {
		max-width: 100%;
		overflow: auto;
	}
}