/* =============================================================================
 * Multi select
 *
 * 1. Thinner scrollbar for Firefox
 * 2. Custom scrollbar for webkit
 *
 * postcss-bem-linter: define multi-select
===================================================================== */
.c-multi-select {
	display:inline-block;
	position:relative;
	width:260px;
	height:42px;
	border:1px solid var(--gray-3);
	border-radius:5px;
	background-color:var(--white);

	@media(--md) {
		/* width:240px; */
		height:48px;
	}
}
.c-multi-select--small {
	@media(--md) {
		width:160px;
	}
}
.c-multi-select--large {
	@media(--md) {
		width:320px;
	}
}
.c-multi-select.is-open {
	z-index:10;
	border-bottom:none;
	border-bottom-right-radius:0;
	border-bottom-left-radius:0;
}
.c-multi-select:focus-within,
.c-multi-select:focus-within .c-multi-select__list {
	border-color:var(--calypso);
	box-shadow:0 4px 10px 0 rgba(0, 0, 0, 0.07);
}
.c-multi-select__trigger {
	display:flex;
	justify-content:space-between;
	align-items:center;
	width:100%;
	height:100%;
	padding:0 15px;
	outline:none;
	border:none;
	background-color:transparent;
	text-align:left;
	cursor:pointer;
}
.c-multi-select.is-open .c-multi-select__trigger::after {
	display:block;
	position:absolute;
	top:0;
	left:-1px;
	z-index:-1;
	width:calc(100% + 2px);
	height:48px;
	border-radius:5px;
	box-shadow:0 4px 10px 0 rgba(0, 0, 0, 0.07);
	content:"";
}
.c-multi-select__label {
	display:block;
	margin:2px 0 0 0;
	font-size:var(--type-sz-5);
	line-height:var(--type-lh-5);
	transition:all 0.03s ease;
}
.c-multi-select__label {
	font-weight:bold;
}
.c-multi-select__label:only-child {
	font-size:var(--type-sz-default);
	line-height:var(--type-lh-default);
}
.c-multi-select__label:only-child {
	font-weight:bold;
}

/* .c-multi-select__container span:only-child {} */
.c-multi-select__icon[class] {
	width:10px;
	margin-left:15px;
	transition:all 0.1s ease;

	@media(--md) {
		margin:2px 2px 0 30px;
	}
}
.c-multi-select.is-open .c-multi-select__icon[class] {
	transition:all 0.1s ease;
	transform:rotate(180deg);
}
.c-multi-select__list {
	display:none;
	position:absolute;
	left:-1px;
	max-height:400px;
	width:calc(100% + 2px);
	overflow-y:scroll;
	margin:0;
	padding:0 0 5px 0;
	outline:none;
	border:1px solid var(--gray-4);
	border-top:none;
	border-bottom-right-radius:5px;
	border-bottom-left-radius:5px;
	box-shadow:0 4px 10px 0 rgba(0, 0, 0, 0.07);
	list-style:none;
	background-color:var(--white);
	scrollbar-width:thin; /* [1] */
}
.c-multi-select__list::before {
	display:block;
	height:1px;
	margin:0 6% 5px 6%;
	background-color:var(--calypso);
	content:"";
}
.c-multi-select__list::-webkit-scrollbar { /* [2] */
	width:10px;
}
.c-multi-select__list::-webkit-scrollbar-track { /* [2] */
	background-color:transparent;
}
.c-multi-select__list::-webkit-scrollbar-thumb { /* [2] */
	border-radius:5px;
	background-color:var(--gray-4);
}
.c-multi-select.is-open .c-multi-select__list {
	display:block;
}
.c-multi-select__option {
	padding:4px 5px;
}
.c-multi-select__option:hover,
.c-multi-select__option.is-active {
	background-color:var(--gray-4);
}
.no-js .c-multi-select {
	border:none;
}
.c-multi-select__group-label {
	margin:8px 0 0;
	padding-top:14px;
	border-top:1px solid var(--gray-4);
	font-weight:bold;
}
