/* =============================================================================
 * Share bar
 *
 * postcss-bem-linter: define share-bar
============================================================================= */
.c-share-bar {
	margin-top:12px;

	@media (--md) {
		margin:0;
	}
}
.c-share-bar__inner {
	position:relative;
}
.c-share-bar__list {
	display:flex;
	justify-content:flex-end;
	margin:0 0 24px 0;
	padding:0;
	list-style:none;

	@media (--md) {
		position:absolute;
		bottom:20px;
		left:-20px;
		justify-content:flex-start;
		margin:4px 0 0;
		flex-flow:column;
	}
}
.c-share-bar--no-image .c-share-bar__list {
	@media (--md) {
		top:60px;
		left:-40px;
	}
}
.c-share-bar__item:not(:last-of-type) {
	margin-right:8px;

	@media (--md) {
		margin-bottom:8px;
	}
}
.c-share-bar__link {
	display:flex;
	justify-content:center;
	align-items:center;
	width:20px;
	height:20px;
	border:1px solid var(--fuscous);
	border-radius:100%;
	background-color:var(--fuscous);
	line-height:1;
}
.c-share-bar__link:hover {
	border-color:var(--fuscous);
	background-color:var(--white);
}
.c-share-bar__icon {
	width:12px;
	height:12px;
	fill:var(--white);
}
.c-share-bar__link:hover .c-share-bar__icon {
	fill:var(--fuscous);
}
