/* =============================================================================
 * Drop-image
 *
 * postcss-bem-linter: define drop-image
============================================================================= */
.c-drop-image {
	position:relative;
	margin:-30px 0 0 0;

	@media (--sm) {
		margin:-60px 0 0 0;
	}

	@media (--md) {
		margin-top:-160px;
	}
}
.c-drop-image img {
	z-index:1;
	width:100%;
	border-radius:5px;
	box-shadow:0 4px 10px rgba(0, 0, 0, 0.15);
}
