/* =============================================================================
 * Split-block
 *
 * postcss-bem-linter: define split-block
============================================================================= */
.c-split-block__limiter {
	max-width:var(--limiter-default);
	margin-right:auto;
	margin-left:auto;
	padding-right:0;
	padding-left:0;
}
.c-split-block {
	@media (--sm) {
		background:linear-gradient(to right, var(--gray-4) 0%, var(--gray-4) 50%, var(--white) 50%, var(--white) 100%);
	}
}
.c-split-block__content {
	display:flex;
	flex-direction:column;
	justify-content:space-between;

	@media (--sm) {
		flex-direction:row;
	}
}
.c-split-block__left {
	width:100%;
	padding:60px 20px 20px;
	background-color:var(--gray-4);
	text-align:center;

	@media (--sm) {
		width:50%;
		text-align:left;

	}
}
.c-split-block__right {
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	width:100%;
	padding:60px 20px 20px;
	background-color:var(--white);
	text-align:center;

	@media (--sm) {
		width:50%;
		text-align:left;
	}
}
.c-split-block__right-inner {
	width:auto;
}
.c-split-block__right-inner h2 {
	margin:0;
}
.c-split-block__right iframe {
	overflow:hidden;
}
.c-button--width-100 {
	width:100%;
	margin-bottom:50px;
}
.c-split-block__iframe-container {
	width:auto;
}
.c-split-block__h3 {
	margin-bottom:0;
	font:var(--type-ff-default);
	font-weight:bold;
}