/* =============================================================================
 * Button
 *
 * postcss-bem-linter: define button
============================================================================= */
.c-button,
.c-button:visited {
	display:inline-flex;
	justify-content:center;
	align-items:center;
	min-width:90px;
	padding:8px 12px;
	border:0;
	border-radius:3px;
	box-shadow:1px 1px 2px 1px rgba(0, 0, 0, 0.05);
	background-color:var(--control-bg);
	color:var(--control-text);
	font:inherit;
	font-weight:500;
	font-size:var(--type-sz-default);
	line-height:inherit;
	line-height:var(--type-lh-default);
	text-decoration:none;
	cursor:pointer;

	@media (--sm) {
		min-width:120px;
		padding:10px 12px;
	}
}
.c-button--no-width {
	min-width:auto;
}
.c-button .c-icon {
	fill:currentColor;
}
.c-button:hover,
.c-button:focus {
	box-shadow:none;
	color:var(--control-text);
	opacity:0.9;
}
.c-button--inverted {
	background-color:var(--control-bg-inverted);
	color:var(--control-text-inverted);
}
.c-button--inverted:hover,
.c-button--inverted:focus {
	color:var(--control-text-inverted);
}
.c-button--disabled {
	background-color:var(--gray-3);
	color:var(--gray-2);
}
.c-button--disabled:hover {
	opacity:1;
}
.c-button--round {
	min-width:auto;
	width:52px;
	height:52px;
	border-radius:50%;
	box-shadow:0 4px 10px 0 rgba(0, 0, 0, 0.07);
	background-color:var(--control-bg);
}
