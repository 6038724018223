/*
=============================================================================
 * Object Listing
 * postcss-bem-linter: define c-object-listing
============================================================================= */
.c-object-listing {
	/* display:none; */
}
.no-js .c-object-listing,
.c-object-listing--is-visible {
	display:block;
}
.c-object-listing__inner {
	display:flex;
	flex-wrap:wrap;
}
.c-object-listing__item {
	width:100%;

	@media (--xs) {
		width:calc(50% - 30px);
		margin:25px 15px;
	}

	@media (--md) {
		width:calc(33% - 30px);
	}

	@media (--lg) {
		width:calc(25% - 30px);
	}
}
.c-object-listing__utilities {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
}
.c-object-listing__title {
	width:100%;
	font-size:var(--type-sz-60);
	line-height:var(--type-lh-60);
	font-family:var(--type-ff-header);
}
.c-object-listing__title span {
	color:var(--link-secondary);
}
.c-object-listing__loading {
	margin:80px 0 160px 0;
}


.selected-filters {
	margin-top: 32px;
	margin-bottom: 22px;
}

.badge {
	display: inline-block;
	background-color: #f0f0f0;
	border: 0.5px #ccc;
	border-radius: 4px;
	padding: 4px 2px 3px 8px;
	margin-right: 8px;
	font-weight: 400;
	line-height: 18px;
	font-size: 14px;
	font-family: "AvenirRegular", Arial, sans-serif;
	margin-bottom: 8px;
}

.badge button {
	background: none;
	border: none;
	cursor: pointer;
	font-size: 14px;
}

.badge button:hover {
	color: red;
}
