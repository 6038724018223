.feature-container {
    max-width: 1130px;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
}
 
.feature-card {
    display: flex;
    flex-direction: column;
    min-width: 555px;
    min-height: 776px;
    margin-bottom: 20px;
    flex: 1;
}
 
.feature-img {
    height: 480px;
    object-fit: cover;
    width: 100%;
    border-radius: 4px 4px 0 0;
}
 
.card-content {
    padding: 60px;
    border-radius: 0 0 4px 4px;
    height: 100%;
    color: white;
}
 
.card-eyebrow {
    font-size: 14px;
    text-transform: uppercase;
    margin: 0 0 0.8em 0;
    font-weight: 600;
}
 
.card-heading {
    font-size: 26px;
    margin: 0 0 0.4em 0;
    font-weight: 600;
}
 
.card-text {
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.6;
}
 
    .card-text p a {
        text-decoration: none;
        color: inherit;
    }
 
        .card-text p a::after {
            content: ">";
            margin-left: 5px;
        }
 
.feature-card-blue {
    background: #154074;
    color: white;
}
 
.feature-card-orange {
    background: #e4580a;
    color: white;
}
 
.feature-card-lightblue{
    background: #4D8BBE;
    color: white;
}
 
.feature-card-lightorange {
    background: #F9A25E;
    color: #323333;
}