/* =============================================================================
 * Grid list
 * postcss-bem-linter: define grid-list
============================================================================= */
.c-grid-list {
	position:relative;
	background:var(--block-bg);
	color:var(--text);
	--space:16px;

	@media (--md) {
		--space:24px;
	}
}
.c-grid-list--spacious {
	--space:24px;

	@media (--md) {
		--space:36px;
	}
}
.c-grid-list__header {
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
	align-items:center;
	margin-bottom:36px;
}
.c-grid-list__title {
	margin:0;
}
.c-grid-list__title span {
	color:var(--contrast);
}
.c-grid-list__grid {
	display:grid;
	grid-gap:36px;
	grid-gap:var(--space);
	row-gap:calc(36px * 1.5);
	row-gap:calc(var(--space) * 1.5);

	@media (--sm) {
		grid-template-columns:repeat(2, 1fr);
	}

	@media (--md) {
		grid-template-columns:repeat(3, 1fr);
	}
}
.c-grid-list__grid--2 {
	@media (--md) {
		grid-template-columns:repeat(2, 1fr);
	}
}
.c-grid-list__grid--4 {
	@media (--lg) {
		grid-template-columns:repeat(4, 1fr);
	}
}
.c-grid-list__footer {
	display:flex;
	justify-content:right;
	margin-top:36px;
}
